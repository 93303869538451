/* eslint-disable no-console */
import { bind } from "decko";

const isSupported = (): boolean => {
	try {
		const key = "test_ss_key";
		sessionStorage.setItem(key, "test value");
		sessionStorage.removeItem(key);
		return true;
	} catch (e) {
		console.warn("Session storage not supported!");
		return false;
	}
};

class SessionStorage {
	private isSessionStorageAvailable: boolean;

	constructor() {
		this.isSessionStorageAvailable = isSupported();
	}

	@bind
	public set(key: string, value: string): void {
		if (!this.isSessionStorageAvailable) return;

		sessionStorage.setItem(key, value);
	}

	@bind
	public get<T>(key: string, defaultValue?: T): T | string | undefined {
		if (!this.isSessionStorageAvailable) return defaultValue;

		const data = sessionStorage.getItem(key);

		try {
			return data ? JSON.parse(data) : defaultValue;
		} catch (e) {
			console.warn(`Error while parsing session storage data for ${key}. Message: ${e.message}. Stack: ${e.stack}`);
			return data || defaultValue;
		}
	}

	@bind
	public remove(key: string): void {
		if (!this.isSessionStorageAvailable) return;

		sessionStorage.remove(key);
	}

	@bind
	public clear() {
		if (!this.isSessionStorageAvailable) return;

		sessionStorage.clear();
	}
}

const storage = new SessionStorage();

export { SessionStorage };
export default storage;
