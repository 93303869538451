import { bind } from "decko";

import BaseApi from "shared/services/api/BaseApi";
import HttpActions from "shared/services/api/core/HttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { convertFormDataForPost, convertFormDataResult } from "./dataConverters/formDataConverter";

import { API_URLS } from "../../constants";
import { FormDataModel } from "../types";

// @injectable()
export default class FormApiService extends BaseApi {
	constructor() {
		super(new HttpActions([]));
	}

	@bind
	public async getFormInfo(formId: string | number) {
		return convertFormDataResult(await this.actions.get(API_URLS.FORM.GET_FORM(formId)));
	}

	@bind
	public async saveFormInfo(data: FormDataModel) {
		return BaseConverter.convert<string>(
			await this.actions.post(API_URLS.FORM.SAVE_FORM, convertFormDataForPost(data))
		);
	}
}
