import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useAuth } from "../../Data/hooks";

interface Props {
	token: string;
	formId?: string | number;
}

const ExternalAuthenticationView: React.FC<Props> = ({ token, formId }) => {
	const { logIn } = useAuth();
	const history = useHistory();

	useEffect(() => {
		logIn(token);
		history.push(`/${formId}`);
	});

	return <div>External auth</div>;
};

export default ExternalAuthenticationView;
