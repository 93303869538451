import { AxiosRequestConfig } from "axios";

import config from "config/appConfig";

import SessionStorage from "../../sessionStorage/sessionStorage";

let cashedToken: string | undefined = undefined;

const setJwt = (token: string): void => {
	cashedToken = token;
	SessionStorage.set(config.TOKEN_KEY, token);
};

const getJwt = (): string | undefined => cashedToken || SessionStorage.get(config.TOKEN_KEY, undefined);

const injectJwt = (config: AxiosRequestConfig): Partial<AxiosRequestConfig> => {
	const token = getJwt();
	const header = token ? { Authorization: `Bearer ${token}` } : {};

	return {
		...config,
		headers: {
			"Content-Type": "application/json",
			...(config ? config.headers || {} : {}),
			...header
		}
	};
};

const clearJwt = (): void => {
	cashedToken = undefined;
	SessionStorage.clear();
};

export { setJwt, getJwt, injectJwt, clearJwt };
