import React, { ReactNode, useEffect } from "react";

import { CssBaseline, StylesProvider } from "@makeen.io/material-ui-kit";
import { MuiThemeProvider } from "@material-ui/core/styles";

import config from "config/appConfig";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ThemeProvider } from "styled-components/macro";

import SessionStorage from "shared/services/sessionStorage/sessionStorage";

import theme from "../theme/default";

interface IAppWrapperProps {
	children: undefined | ReactNode;
}

const AppWrapper: React.FC<IAppWrapperProps> = ({ children }) => {
	useEffect(() => {
		const isAuthorised = SessionStorage.get(config.TOKEN_KEY, undefined);

		if (!isAuthorised) {
			window.location.replace(config.CORE_API_URLS.LOGIN);
		}
	});

	return (
		<MuiThemeProvider theme={theme}>
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<DndProvider backend={HTML5Backend}>{children}</DndProvider>
				</ThemeProvider>
			</StylesProvider>
		</MuiThemeProvider>
	);
};

export default AppWrapper;
