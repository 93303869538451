import React from "react";

import { BuilderCard, Icon, Text, Tooltip } from "@makeen.io/material-ui-kit";

import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

import theme from "theme/default";

import { useControlList } from "../../../Data";
import { ControlItemDataModel } from "../../../Data/types";

export const ControlBodyWrapper = styled.div<
	React.HTMLAttributes<HTMLDivElement> & {
		layout?: "regular" | "controls" | "flex";
		noPadding?: boolean;
		withBorder?: boolean;
	}
>`
	overflow: hidden;
	${({ layout = "regular", noPadding, withBorder }) => {
		let styles = "";

		if (withBorder) {
			styles += `
				border-bottom: 0.5px solid ${theme.palette.colors.basic[500]};
			`;
		}

		if (layout !== "regular") {
			styles += `
				display: flex;
				flex-flow: row wrap;
				align-content: flex-start;
				justify-content: flex-start;
			`;
		}

		if (!noPadding) {
			if (layout !== "controls") {
				styles += `
					padding: 24px;
				`;
			} else {
				styles += `
					padding: 16px
				`;
			}
		}

		return styles;
	}}
`;

const InfoText = styled.div`
	color: #8f9bb3;
	height: 50px;
	margin: 40px;
	text-align: center;
`;

const CloneWrapper = styled.div`
	margin: 8px;
`;

const Wrapper = styled(CloneWrapper)`
	transform: none !important;
`;

const Control = ({ isDragging, ...rest }) => (
	<BuilderCard {...rest} style={isDragging ? { border: "1px solid #4350f7", opacity: 0.9 } : {}} />
);

const ControlBody: React.FC = () => {
	const { controlList } = useControlList();

	const getStyle = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
		const style = provided.draggableProps.style;

		const { isDropAnimating, dropAnimation, draggingOver } = snapshot;

		if (!isDropAnimating || !dropAnimation) {
			return style;
		}

		const { moveTo } = dropAnimation;

		if (draggingOver === "frame") {
			moveTo.x += 547 / 2 - 30;
		} else {
			moveTo.x = moveTo.y = 0;
		}

		const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
		return {
			...style,
			transform: `${translate}`
		};
	};

	const renderClone = (provided, snapshot, rubric) => {
		const card = controlList ? controlList[rubric.source.index] : ({} as ControlItemDataModel);
		return (
			<CloneWrapper
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
				style={getStyle(provided, snapshot)}
			>
				{renderCard(card, true)}
			</CloneWrapper>
		);
	};

	const renderCard = (card, isDragging) => (
		<Control isDragging={isDragging}>
			<BuilderCard.Icon>
				<Icon fill={theme.palette.colors.basic[1000]} group={card.controlIconGroup} name={card.controlIconName} />
			</BuilderCard.Icon>
			<BuilderCard.Title>
				<Text variant="subtitle2">{card.controlName}</Text>
			</BuilderCard.Title>
		</Control>
	);

	return (
		<>
			<Droppable droppableId="elements" type="element" isDropDisabled={true} renderClone={renderClone}>
				{(p, s) => (
					<ControlBodyWrapper layout="controls" ref={p.innerRef} {...p.droppableProps}>
						{controlList?.map((card, index) =>
							card.controlKey === s.draggingFromThisWith ? (
								<Wrapper key={card.controlKey}>{renderCard(card, false)}</Wrapper>
							) : (
								<Draggable key={card.controlKey} draggableId={card.controlKey} index={index}>
									{(provided, snapshot) => (
										<Wrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
											<Tooltip text={card.tooltip} enabled={!!card.tooltip}>
												{renderCard(card, snapshot.isDragging)}
											</Tooltip>
										</Wrapper>
									)}
								</Draggable>
							)
						)}
					</ControlBodyWrapper>
				)}
			</Droppable>
			<InfoText>
				<Text variant="body2">Drag the elements on the canvas</Text>
			</InfoText>
		</>
	);
};

export default ControlBody;
