import React from "react";

import { Text } from "@makeen.io/material-ui-kit";
import { FormControl, FormLabel, InputAdornment, Switch, TextField, createStyles, makeStyles } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

import { FlexItem } from "../../Containers";
import { ValidationAdornmentIcon, ValidationStatus, debouncedValidateUrl } from "../../helpers";

const useStyles = makeStyles(theme =>
	createStyles({
		// todo: move to theme
		formControl: {
			marginTop: theme.spacing(2)
		}
	})
);

const WeblinkProps = () => {
	const classes = useStyles();

	const { register, watch, control } = useFormContext();

	const url = watch("weblink-url", "");

	const [urlValidationStatus, setUrlValidationStatus] = React.useState<ValidationStatus>("");

	const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const url = event.target.value;

		// clear validation status while typing
		setUrlValidationStatus("");

		if (url.length > 0) {
			debouncedValidateUrl(url, setUrlValidationStatus);
		}
	};

	const showError = url.length > 0 && urlValidationStatus === "invalid";

	return (
		<>
			<FormControl className={classes.formControl}>
				<FormLabel>
					<Text variant="subtitle1">Web address</Text>
				</FormLabel>
				<TextField
					inputRef={register}
					autoComplete="off"
					error={showError}
					helperText={showError && "Web address is invalid"}
					id="weblink-url-input"
					name="weblink-url"
					onChange={handleUrlChange}
					placeholder="https://"
					variant="outlined"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<ValidationAdornmentIcon status={urlValidationStatus} />
							</InputAdornment>
						)
					}}
				/>
			</FormControl>

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Show thumbnail of the webpage</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							control={control}
							color="primary"
							id="weblink-show-thumbnail-switch"
							name="showThumbnail"
							inputProps={{ "aria-label": "Switch A" }}
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>
		</>
	);
};

export default WeblinkProps;
