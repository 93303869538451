import React from "react";

import { Icon, Text, Tooltip } from "@makeen.io/material-ui-kit";

import theme from "theme/default";

import { useForm, useFrame } from "../../../Data";
import { deviceType } from "../../../Data/types";
import { DeviceButton } from "../index";

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
	const { form } = useForm();
	const { frameDevice, changeDevice } = useFrame();

	return (
		<>
			<div className="column left">
				<div className="form-name">
					<Text variant={"subtitle1"}>{form.DisplayTitle}</Text>
				</div>
				<div className="form-version">
					<Tooltip text="Current version number">
						<Text variant="caption">Version {form.Version}</Text>
					</Tooltip>
				</div>
			</div>
			<div className="column middle">
				<DeviceButton
					inactive={frameDevice === "smartphone"}
					onClick={() => frameDevice !== "smartphone" && changeDevice(deviceType.smartphone)}
				>
					<Icon
						fill={theme.palette.colors.basic[frameDevice === "smartphone" ? 900 : 600]}
						group="linear"
						name="mobile"
						height={frameDevice === "smartphone" ? 23 : 21}
					/>
				</DeviceButton>
				<DeviceButton
					inactive={frameDevice === "tablet"}
					onClick={() => frameDevice !== "tablet" && changeDevice(deviceType.tablet)}
				>
					<Icon
						fill={theme.palette.colors.basic[frameDevice === "tablet" ? 900 : 600]}
						group="linear"
						name="tablet-portrait"
						height={frameDevice === "tablet" ? 23 : 21}
					/>
				</DeviceButton>
			</div>
			<div className="column right">
				{form.PublishedAt && (
					<div className="form-published">
						<Text className="label" variant="caption1">
							Last Published on
						</Text>
						<time dateTime={form.PublishedAt?.toFormat("MMMM DD, yyyy")}>
							<Text variant="caption2" display="inline">
								{form.PublishedAt}
							</Text>
							<span className="pipe"> | </span>
							<Text variant="caption2" display="inline">
								{form.PublishedAt}
							</Text>
						</time>
						{form.PublishedAt && (
							<>
								<span className="pipe"> | </span>
								<time dateTime={form.PublishedAt.toString()} className="outdated">
									<Text variant="caption2" display="inline">
										{form.PublishedAt?.diffNow("months")}
									</Text>
								</time>
							</>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default Header;
