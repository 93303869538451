import styled from "styled-components";

import theme from "theme/default";

import { deviceType } from "../../../Data/types";

const DeviceButton = styled.button<
	React.HTMLAttributes<HTMLButtonElement> & {
		device?: deviceType;
		inactive?: boolean;
	}
>`
	background-color: ${({ inactive = false }) => (inactive ? theme.palette.colors.basic[500] : "#fff")};
	border-radius: 4px;
	border: 1px solid ${({ inactive = false }) => (inactive ? "transparent" : theme.palette.colors.basic[400])};
	cursor: ${({ inactive = false }) => (inactive ? "not-allowed" : "pointer")};
	display: inline-flex;
	margin: auto 3px;
	outline: none;
	padding: 8px;
`;

export default DeviceButton;
