import React from "react";

import { Icon, Button as MukButton, Text } from "@makeen.io/material-ui-kit";
import * as R from "ramda";
import styled from "styled-components";

import { FlexItem } from "../../Containers";

interface Props {
	data: any;
	propsList: string[];
	children: React.ReactNode;
}

type ControlDescriptionProps = {
	text: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ControlDescription = styled(({ text, ...props }: ControlDescriptionProps) => (
	<div {...props}>
		<Text variant="subtitle1">{text}</Text>
	</div>
))`
	margin-bottom: 15px;
`;

const UserPicker: React.FC<Props> = ({ data, propsList, children }) => {
	const [userPickerDescription, userPickerButtonText] = R.props(propsList, data);

	return (
		<>
			{userPickerDescription && <ControlDescription text={userPickerDescription} />}
			<FlexItem direction="column">
				<MukButton leftIcon={<Icon fill="#fff" group="filled" name="user" />}>
					{userPickerButtonText || children}
				</MukButton>
			</FlexItem>
		</>
	);
};

export default UserPicker;
