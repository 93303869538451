import React, { ReactNode, useMemo, useState } from "react";

import FrameContext, { initialState } from "./FrameContext";

interface Props {
	children: ReactNode;
}

const FrameContextProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const context = useMemo(() => ({ ...state, setState }), [state, setState]);

	return <FrameContext.Provider value={context}>{children}</FrameContext.Provider>;
};

export default FrameContextProvider;
