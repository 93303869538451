import React from "react";

import { Icon, Button as MukButton, Text } from "@makeen.io/material-ui-kit";
import * as R from "ramda";
import styled from "styled-components";

import theme from "theme/default";

import { FlexItem } from "../../Containers";

interface Props {
	data: any;
	propsList: string[];
}

type ControlDescriptionProps = {
	text: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ControlDescription = styled(({ text, ...props }: ControlDescriptionProps) => (
	<div {...props}>
		<Text variant="subtitle1">{text}</Text>
	</div>
))`
	margin-bottom: 15px;
`;

const DateTime: React.FC<Props> = ({ data, propsList }) => {
	const [showDescription, showButtonText, dateTimeDescription, dateTimeButtonText, datePicker, timePicker] = R.props(
		propsList,
		data
	);

	const defaultButtonText = () => {
		if (datePicker && timePicker) {
			return "Select Date and Time";
		} else if (datePicker) {
			return "Select Date";
		} else if (timePicker) {
			return "Select Time";
		} else {
			return "Select Date"; // since Date is checked by default, add its button text by default
		}
	};
	const buttonStyles = `
		&, &:hover {
			background-color: ${theme.palette.colors.basic[600]};
		}
	`;

	return (
		<>
			{showDescription && dateTimeDescription && <ControlDescription text={dateTimeDescription} />}
			<FlexItem direction="column">
				<MukButton customStyle={() => buttonStyles} leftIcon={<Icon fill="#fff" group="filled" name="calendar" />}>
					{showButtonText && dateTimeButtonText ? dateTimeButtonText : defaultButtonText()}
				</MukButton>
			</FlexItem>
		</>
	);
};

export default DateTime;
