import { appTheme } from "@makeen.io/material-ui-kit";
import { createMuiTheme } from "@material-ui/core";
import { TypographyOptions, TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

const { overrides, palette, props, sizes } = appTheme;
const { colors } = palette;

type ExtendedTheme = typeof appTheme;

interface CustomTypography extends TypographyOptions {
	caption1: TypographyStyleOptions;
}

const customTheme = createMuiTheme(
	{
		palette,
		overrides: {
			...overrides,
			MuiCircularProgress: {
				colorPrimary: {
					color: colors.primary[500]
				}
			},
			MuiFormControl: {
				root: {
					// marginTop: 16,
					width: "100%"
				}
			},
			MuiFormLabel: {
				root: {
					color: colors.basic[800],
					marginBottom: 6,
					"&$focused": {
						color: colors.basic[800]
					}
				}
			},
			MuiInputAdornment: {
				positionEnd: {
					marginRight: 12
				}
			},
			MuiList: {
				padding: {
					paddingBottom: 0,
					paddingTop: 0
				}
			},
			MuiMenu: {
				paper: {
					boxShadow: undefined
				}
			},
			MuiMenuItem: {
				root: {
					borderRadius: 4,
					paddingBottom: 10,
					paddingTop: 10,
					"&$selected": {
						backgroundColor: colors.basic[300]
					}
				}
			},
			MuiOutlinedInput: {
				adornedEnd: {
					paddingRight: 0
				},
				input: {
					borderColor: colors.basic[400],
					fontSize: "0.8125rem", // 13px
					height: 24,
					lineHeight: 1.85,
					padding: "12px 16px",
					"&::placeholder": {
						color: colors.basic[600],
						fontWeight: 600,
						opacity: 1
					}
				},
				multiline: {
					minHeight: 72,
					padding: "12px 16px"
				},
				notchedOutline: {
					borderColor: colors.basic[400],
					top: -5
				}
			},
			MuiSelect: {
				select: {
					color: colors.basic[600],
					fontSize: "0.8125rem", // 13px
					fontWeight: 600,
					lineHeight: 1.85,
					"&:focus": {
						backgroundColor: "none"
					}
				}
			}
		},
		props: {
			...props,
			MuiMenu: {
				elevation: 4
			}
		},
		typography: {
			fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
			h1: {
				fontSize: "2.25rem", // 36px
				fontWeight: 700,
				lineHeight: 1.33
			},
			h2: {
				fontSize: "2rem", // 32px
				fontWeight: 700,
				lineHeight: 1.25
			},
			h3: {
				fontSize: "1.875rem", // 30px
				fontWeight: 700,
				lineHeight: 1.33
			},
			h4: {
				fontSize: "1.625rem", // 26px
				fontWeight: 700,
				lineHeight: 1.23
			},
			h5: {
				fontSize: "1.375rem", // 22px
				fontWeight: 700,
				lineHeight: 1.45
			},
			h6: {
				fontSize: "1.125rem", // 18px
				fontWeight: 700,
				lineHeight: 1.33
			},
			subtitle1: {
				fontSize: "0.9375rem", // 15px
				fontWeight: 600,
				lineHeight: 1.6
			},
			subtitle2: {
				fontSize: "0.8125rem", // 13px
				fontWeight: 600,
				lineHeight: 1.85
			},
			body1: {
				fontSize: "0.9375rem", // 15px
				lineHeight: 1.33
			},
			body2: {
				fontSize: "0.8125rem", // 13px
				lineHeight: 1.23
			},
			caption: {
				fontSize: "0.75rem", // 12px
				lineHeight: 1.33
			},
			caption1: {
				fontSize: "0.75rem", // 12px
				lineHeight: 1.33
			},
			caption2: {
				fontSize: "0.75rem", // 12px
				fontWeight: 600,
				lineHeight: 1.33
			},
			label: {
				fontSize: "0.75rem", // 12px
				lineHeight: 1.33,
				textTransform: "uppercase"
			},
			small1: {
				fontSize: "0.625rem", // 10px
				fontWeight: 600,
				lineHeight: "normal"
			},
			small2: {
				fontSize: "0.625rem", // 10px
				fontWeight: 600,
				lineHeight: "normal"
			}
		} as CustomTypography
	},
	{
		sizes: {
			...sizes,
			questionCard: {
				actionItem: {
					fontSize: "12px",
					lineHeight: "normal",
					iconSize: 24,
					marginLeft: "12px",
					marginRight: "12px"
				},
				actions: {
					height: "40px",
					left: {
						padding: "0"
					},
					padding: "9px 12px",
					right: {
						padding: "0"
					}
				},
				content: {
					padding: "20px"
				},
				iconButton: {
					marginLeft: "0",
					marginRight: "0",
					marginTop: "auto",
					marginBottom: "auto"
				}
			}
		}
	}
) as ExtendedTheme;

export default { ...appTheme, ...customTheme };
