import { IStateProps } from "utils/StateOf";
import { generateContext } from "utils/generateContext";

import { ApplicationModel, EnvironmentType } from "../../types";

interface Values {
	application?: ApplicationModel;
	environment?: EnvironmentType;
}

export const initialState: IStateProps<Values> = {
	application: undefined,
	environment: undefined
};

export default generateContext<Values>();
