import React, { ReactNode, useMemo, useState } from "react";

import FormContext from "./FormContext";
import initialState from "./init";

interface Props {
	children: ReactNode;
}

const FormContextProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const context = useMemo(() => ({ ...state, setState }), [state, setState]);

	return <FormContext.Provider value={context}>{children}</FormContext.Provider>;
};

export default FormContextProvider;
