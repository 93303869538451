import React, { useEffect } from "react";

import { useAuth } from "../../Data/hooks";

const LogOutView = () => {
	const { logOut } = useAuth();

	useEffect(() => {
		logOut();
	});

	return <div>Logging Out...</div>;
};

export default LogOutView;
