import React from "react";

import { Icon, Text } from "@makeen.io/material-ui-kit";
import { FormControl, FormLabel, InputAdornment, TextField, createStyles, makeStyles } from "@material-ui/core";

import theme from "theme/default";

const useStyles = makeStyles(MuiTheme =>
	createStyles({
		// todo: move to theme
		formControl: {
			marginTop: MuiTheme.spacing(2)
		},
		textField: {
			backgroundColor: theme.palette.colors.basic[300]
		}
	})
);

const ReusableFormProps = () => {
	const classes = useStyles();

	const placeholder = {
		reusableForm: "Search reusable form"
	};

	return (
		<>
			<FormControl className={classes.formControl}>
				<FormLabel>
					<Text variant="subtitle1">Attach reusable form</Text>
				</FormLabel>
				<TextField
					className={classes.textField}
					id="reusable-form-input"
					multiline
					placeholder={placeholder.reusableForm}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Icon fill={theme.palette.colors.basic[600]} group="linear" name="search" height={15} />
							</InputAdornment>
						)
					}}
					variant="outlined"
				/>
			</FormControl>
		</>
	);
};

export default ReusableFormProps;
