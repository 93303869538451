import { useCallback, useContext, useMemo } from "react";

import { generateUid } from "utils/UidGenerator";

import { FormContext } from "../contexts";
import { FormApiService } from "../services";
import { FormBodyViewsType, FormControlModel, FormDataModel } from "../types";

const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useForm = () => {
	const context = useContext(FormContext);

	const api = useMemo(() => new FormApiService(), []);

	const getFormControl = useCallback(
		(cUid: string) => context.form.formControls?.find(({ controlUID }) => controlUID === cUid),
		[context]
	);

	const setState = context.setState;

	const saveFormData = useCallback(
		async (form: FormDataModel, activeElementUID?: string) => {
			setState(ctx => ({ ...ctx, form, isSaving: true }));
			await api.saveFormInfo(form);
			setState(ctx => ({ ...ctx, isSaving: false, activeElementUID }));
		},
		[api, setState]
	);

	const methods = useMemo(
		() => ({
			loadForm: async formId => {
				const form = await api.getFormInfo(formId);
				setState(ctx => ({ ...ctx, form }));
			},
			changeControlBodyView(formBodyView: FormBodyViewsType) {
				setState(ctx => ({ ...ctx, formBodyView }));
			},
			setComponentActive(activeElementUID: string) {
				setState(ctx => ({
					...ctx,
					activeElementUID
				}));
			},
			addComponent: async (controlKey: string, index: number) => {
				setState(ctx => {
					const control: FormControlModel = {
						controlUID: `frame_${generateUid()}`,
						controlKey,
						controlData: undefined
					};

					let items = ctx.form.formControls;

					if (!items.length) {
						items = [...items, control];
					} else {
						items.splice(index, 0, control);
					}

					const form = {
						...ctx.form,
						formControls: items
					};

					saveFormData(form, control.controlUID);
					return ctx;
				});
			},
			reorderComponents: (index: number, endIndex: number) => {
				if (index !== endIndex) {
					setState(ctx => {
						const form = { ...ctx.form, formControls: reorder(ctx.form.formControls, index, endIndex) };

						saveFormData(form);

						return ctx;
					});
				}
			},
			saveComponentData: async (data: any, newUID?: string) => {
				setState(ctx => {
					const { form, activeElementUID } = ctx;
					const { formControls } = form;
					const controlIndex = formControls.findIndex(({ controlUID }) => controlUID === activeElementUID);

					if (controlIndex > -1) {
						formControls[controlIndex].controlData = data;

						const form = {
							...ctx.form,
							formControls
						};

						saveFormData(form, newUID);
					}

					return { ...ctx, activeElementUID: newUID };
				});
			},
			discardComponentDataChanges: () => {
				setState(ctx => ({ ...ctx, activeElementUID: undefined }));
			},
			deleteFormControl: () => {
				setState(ctx => {
					const {
						form: { formControls },
						activeElementUID
					} = ctx;

					const controlIndex = formControls.findIndex(({ controlUID }) => controlUID === activeElementUID);
					if (controlIndex > -1) {
						formControls.splice(controlIndex, 1);
						const form = {
							...ctx.form,
							formControls
						};

						saveFormData(form);
					}

					return { ...ctx, activeElementUID: undefined };
				});
			},
			duplicateControl: () => {
				setState(ctx => {
					const { form, activeElementUID } = ctx;
					const { formControls } = form;
					const controlIndex = formControls.findIndex(({ controlUID }) => controlUID === activeElementUID);

					if (controlIndex > -1) {
						const newControl = { ...formControls[controlIndex], controlUID: `frame_${generateUid()}` };
						formControls.splice(controlIndex, 0, newControl);
						const form = {
							...ctx.form,
							formControls
						};

						saveFormData(form);
					}

					return { ...ctx, activeElementUID: undefined };
				});
			}
		}),
		[api, saveFormData, setState]
	);

	return useMemo(
		() => ({
			...context,
			...methods,
			getFormControl
		}),
		[context, getFormControl, methods]
	);
};

export default useForm;
