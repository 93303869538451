import React, { ReactNode, useMemo, useState } from "react";

import FormStreamContext, { initialState } from "./FormStreamContext";

interface Props {
	children: ReactNode;
}

const FormStreamContextProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const context = useMemo(() => ({ ...state, setState }), [state, setState]);

	return <FormStreamContext.Provider value={context}>{children}</FormStreamContext.Provider>;
};

export default FormStreamContextProvider;
