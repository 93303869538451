import React from "react";

import { Icon, Tab, Tabs } from "@makeen.io/material-ui-kit";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import theme from "theme/default";

import { useForm } from "../../../Data";

export const ControlHeaderWrapper = styled.div<
	React.HTMLAttributes<HTMLDivElement> & {
		customStyle?: string;
	}
>`
	min-height: 56px;
	padding: 16px 24px;
	border-bottom: 0.5px solid ${theme.palette.colors.basic[500]};
	display: flex;
	align-items: center;
	justify-content: center;

	${({ customStyle }) => customStyle}
`;

interface ControlHeaderProps {}

const ControlHeader: React.FC<ControlHeaderProps> = () => {
	const useStyles = makeStyles(MuiTheme => ({
		tabs: {
			"& .MuiTab-root": {
				borderWidth: 1,
				opacity: 1,
				paddingBottom: 8,
				paddingTop: 8
			},
			"& .MuiTab-root:last-child": {
				borderLeft: `1px solid ${theme.palette.colors.basic[500]}`
			},
			"& .MuiTab-wrapper": {
				flexDirection: "row",
				justifyContent: "flex-start",

				"& svg": {
					marginRight: MuiTheme.spacing(1)
				}
			}
		}
	}));
	const classes = useStyles();
	const { formBodyView, changeControlBodyView } = useForm();

	return (
		<ControlHeaderWrapper>
			<Tabs
				value={formBodyView}
				onChange={(e, v) => changeControlBodyView(v)}
				variant="fullWidth"
				style={{ width: "100%" }}
				className={classes.tabs}
			>
				<Tab
					palette="primary"
					value={"elements"}
					label={
						<>
							<Icon
								fill={theme.palette.colors.basic[formBodyView === "elements" ? 900 : 500]}
								group={"color"}
								name="tiles-gray"
								height={20}
							/>
							<Typography variant="subtitle1">Elements</Typography>
						</>
					}
				/>
				<Tab
					palette="primary"
					value={"general"}
					label={
						<>
							<Icon
								fill={theme.palette.colors.basic[formBodyView === "general" ? 900 : 500]}
								group={"color"}
								name="box-gray"
								height={20}
							/>
							<Typography variant="subtitle1">General</Typography>
						</>
					}
				/>
			</Tabs>
		</ControlHeaderWrapper>
	);
};

export default ControlHeader;
