import { useCallback, useContext, useMemo } from "react";

import { ControlListContext } from "../contexts";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFormStream = () => {
	const context = useContext(ControlListContext);

	const getControlInfo = useCallback(
		(ctrlKey?: string) =>
			!ctrlKey ? undefined : context.controlList?.find(({ controlKey }) => controlKey === ctrlKey),
		[context]
	);

	return useMemo(
		() => ({
			...context,
			getControlInfo
		}),
		[context, getControlInfo]
	);
};

export default useFormStream;
