import React from "react";

import { useParams } from "react-router";

import { view } from "modules/App";

const ExternalLoginPage = () => {
	const { token, formId } = useParams();
	return <view.ExternalAuthenticationView token={token} formId={formId} />;
};

export default ExternalLoginPage;
