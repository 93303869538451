import React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
	padding: 16px;
`;

const GeneralBody = () => {
	return <Wrapper>General Tab Data</Wrapper>;
};

export default GeneralBody;
