import React, { useMemo } from "react";

import { Button, Icon, Text, Tooltip } from "@makeen.io/material-ui-kit";
import { useFormContext } from "react-hook-form";

import theme from "theme/default";

import { useControlList, useForm } from "../../../Data";
import { ControlItemDataModel } from "../../../Data/types";

import { ControlHeaderWrapper } from "../ControlHeader";

const ElementControlHeader: React.FC = () => {
	const { saveComponentData, activeElementUID, getFormControl } = useForm();
	const { getControlInfo } = useControlList();

	const formControl = useMemo(() => (activeElementUID ? getFormControl(activeElementUID) : undefined), [
		activeElementUID,
		getFormControl
	]);
	const controlInfo = formControl ? getControlInfo(formControl.controlKey) : ({} as ControlItemDataModel);

	const { handleSubmit } = useFormContext();

	return (
		<ControlHeaderWrapper
			customStyle={`
						justify-content: left;
						padding: 9px 24px 8px;
					`}
		>
			<Text variant="h6">{controlInfo?.controlName}</Text>
			<span style={{ marginBottom: -4, marginLeft: 10 }}>
				<Tooltip text={controlInfo?.tooltip || ""}>
					<Icon fill={theme.palette.colors.basic[500]} group="filled" name="info-circle" height={20} width={20} />
				</Tooltip>
			</span>
			<Button
				buttonTheme="outline"
				leftIcon={<Icon fill={theme.palette.colors.basic[500]} group="filled" name="arrow-left" />}
				onClick={() => handleSubmit(d => saveComponentData(d))()}
				size="small"
				style={{ marginLeft: "auto" }}
			>
				Elements
			</Button>
		</ControlHeaderWrapper>
	);
};

export default ElementControlHeader;
