import { IStateProps } from "utils/StateOf";
import { generateContext } from "utils/generateContext";

import { ControlItemDataModel } from "../../types";

interface Values {
	controlList?: ControlItemDataModel[];
}

export const initialState: IStateProps<Values> = {
	controlList: [
		{
			ID: 1,
			controlKey: "c_textbox",
			controlIconGroup: "thin",
			controlIconName: "text",
			controlName: "Text",
			category: "Standard",
			controlOrder: 1,
			tooltip: "Add text to your form",
			isRequireable: false,
			initState: {}
		},
		{
			ID: 2,
			controlKey: "c_question",
			controlIconGroup: "thin",
			controlIconName: "question",
			controlName: "Question",
			category: "Standard",
			controlOrder: 2,
			tooltip: "Allows you to add different types of questions to the form",
			isRequireable: true,
			initState: {}
		},
		{
			ID: 3,
			controlKey: "c_divider",
			controlIconGroup: "thin",
			controlIconName: "divider",
			controlName: "Divider",
			category: "Standard",
			controlOrder: 3,
			tooltip: "Divider allows you to introduce a visual break on the form",
			isRequireable: false,
			initState: {
				"divider-style": "solid"
			}
		},
		{
			ID: 4,
			controlKey: "c_image",
			controlIconGroup: "thin",
			controlIconName: "image",
			controlName: "Display Image",
			category: "Standard",
			controlOrder: 4,
			tooltip: "Allows you to add up to 5 images on the form",
			isRequireable: false,
			initState: {}
		},
		{
			ID: 5,
			controlKey: "c_signature",
			controlIconGroup: "thin",
			controlIconName: "signature",
			controlName: "Signature",
			category: "Standard",
			controlOrder: 5,
			tooltip: "Allows users to add a signature",
			isRequireable: true,
			initState: {
				"show-description": false,
				"show-button-text": false,
				"signature-description": undefined,
				"signature-button-text": undefined
			}
		},
		{
			ID: 6,
			controlKey: "c_button",
			controlIconGroup: "thin",
			controlIconName: "button",
			controlName: "Button",
			category: "Standard",
			controlOrder: 6,
			tooltip: "Allows users to send a text, make phone call and open a web page",
			isRequireable: false,
			initState: {
				"button-text": undefined,
				"button-action": ""
			}
		},
		{
			ID: 7,
			controlKey: "c_web",
			controlIconGroup: "thin",
			controlIconName: "web",
			controlName: "Weblink",
			category: "Standard",
			controlOrder: 7,
			tooltip: "Share a web link on the form",
			isRequireable: false,
			initState: {}
		},
		{
			ID: 8,
			controlKey: "c_form",
			controlIconGroup: "thin",
			controlIconName: "child-form",
			controlName: "Reusable Form",
			category: "Standard",
			controlOrder: 8,
			tooltip: "Use this element to display another form on this form",
			isRequireable: false,
			initState: {}
		},
		{
			ID: 9,
			controlKey: "c_photo",
			controlIconGroup: "thin",
			controlIconName: "photo",
			controlName: "Photo Upload",
			category: "Standard",
			controlOrder: 9,
			tooltip: "Allows users to upload photos",
			isRequireable: true,
			initState: {
				"show-description": false,
				"show-button-text": false,
				"photo-upload-description": undefined,
				"photo-upload-button-text": undefined
			}
		},
		{
			ID: 10,
			controlKey: "c_file-upload",
			controlIconGroup: "thin",
			controlIconName: "file-upload",
			controlName: "File Upload",
			category: "Standard",
			controlOrder: 10,
			tooltip: "Allows users to upload files",
			isRequireable: true,
			initState: {
				"show-description": false,
				"show-button-text": false,
				"file-upload-description": undefined,
				"file-upload-button-text": undefined
			}
		},
		{
			ID: 11,
			controlKey: "c_date-time",
			controlIconGroup: "thin",
			controlIconName: "date-time",
			controlName: "Date / Time",
			category: "Standard",
			controlOrder: 11,
			tooltip: "Choose and add date and time on the form",
			isRequireable: true,
			initState: {
				"show-description": false,
				"show-button-text": false,
				"date-time-description": undefined,
				"date-time-button-text": undefined,
				"date-picker": undefined,
				"time-picker": undefined
			}
		},
		{
			ID: 12,
			controlKey: "c_location",
			controlIconGroup: "thin",
			controlIconName: "location",
			controlName: "Location",
			category: "Standard",
			controlOrder: 12,
			tooltip: "Allows end users to add their locations to the form",
			isRequireable: true,
			initState: {
				"show-description": false,
				"show-button-text": false,
				"location-description": undefined,
				"location-button-text": undefined
			}
		},
		{
			ID: 13,
			controlKey: "c_profile",
			controlIconGroup: "thin",
			controlIconName: "profile",
			controlName: "User Picker",
			category: "Standard",
			controlOrder: 13,
			tooltip: "Choose users that could be selected by end users",
			isRequireable: false,
			initState: {
				"user-picker-description": undefined,
				"user-picker-button-text": undefined
			}
		},
		{
			ID: 14,
			controlKey: "c_barcode",
			controlIconGroup: "thin",
			controlIconName: "barcode",
			controlName: "Barcode",
			category: "Standard",
			controlOrder: 14,
			tooltip: "Allows users to scan a barcode",
			isRequireable: true,
			initState: {
				"barcode-button-text": undefined,
				"barcode-description": undefined,
				"show-button-text": false,
				"show-description": false
			}
		},
		{
			ID: 15,
			controlKey: "c_video",
			controlIconGroup: "thin",
			controlIconName: "video",
			controlName: "YouTube Video",
			category: "Standard",
			controlOrder: 15,
			tooltip: "Allows users to share a youtube video on the form",
			isRequireable: false,
			initState: {}
		}
	]
};

export default generateContext<Values>();
