import React, { ReactNode, useMemo } from "react";

import { Snackbar, Text } from "@makeen.io/material-ui-kit";
import { CircularProgress } from "@material-ui/core";

import { FormProvider, useForm as useRHForm } from "react-hook-form";
import styled from "styled-components";

import theme from "theme/default";

import { useForm } from "../../../Data";
import { ControlListContextProvider, FormStreamContextProvider, FrameContextProvider } from "../../../Data/contexts";
import { ElementView } from "../../../Data/types";
import { getFormViewComponent } from "../../helpers";
import { ControlHeader, ControlWrapper, ElementControlHeader, Header } from "../index";

const AsideWrapper = styled.div`
	width: 100%;
	background: #f7f9fc;
	border-right: 0.5px solid #c5cee0;

	.frame-wrapper {
		display: flex;
		justify-content: center;
		padding: 26px;
	}
`;

const FormHeader = styled.div`
	background-color: ${theme.palette.colors.basic[300]};
	padding: 8px 24px;
	box-shadow: 0 0.5px 0 0 ${theme.palette.colors.basic[500]};
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
	justify-content: space-between;

	.column {
		flex: 1;
		width: 33%;
	}

	.left {
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-start;
		text-align: left;
	}

	.middle {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		text-align: center;

		button {
			height: 40px;
			width: 40px;
			min-width: 0;
			margin-top: auto;
			margin-bottom: auto;
			padding: 8px;
		}
	}

	.right {
		display: flex;
		flex-flow: column wrap;
		justify-content: flex-end;
		text-align: right;
	}

	.form {
		&-published {
			&-datetime {
				color: red;
			}

			.label {
				color: #8f9bb3;
			}

			.outdated {
				color: ${theme.palette.colors.danger[500]};
			}

			.pipe {
				color: ${theme.palette.colors.basic[600]};
			}
		}

		&-version {
			color: #8f9bb3;
		}
	}
`;

const ControlPanelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 480px;
	background: #ffffff;

	.controls-body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: inherit;
		max-width: 480px;
		padding: 0;
	}
`;

const SaveMessageWrapper = styled.div`
	display: flex;
	align-items: center;
	min-width: 114px;
	justify-content: space-evenly;
`;

const CustomSnackbar = styled(Snackbar)`
	.MuiSnackbarContent-root {
		min-width: 144px;
	}
`;

interface FrameLayoutsProps {
	children: ReactNode;
}

const FrameLayout: React.FC<FrameLayoutsProps> = ({ children }) => {
	const { activeElementUID, getFormControl, formBodyView, isSaving } = useForm();

	const formControlData = useMemo(() => getFormControl(activeElementUID || "")?.controlData, [
		activeElementUID,
		getFormControl
	]);

	const methods = useRHForm({ defaultValues: formControlData });

	const activeControl: ElementView = useMemo(
		() =>
			activeElementUID
				? {
						header: <ElementControlHeader />,
						body: <ControlWrapper />
				  }
				: {
						header: <ControlHeader />,
						body: getFormViewComponent(formBodyView)
				  },
		[activeElementUID, formBodyView]
	);

	return (
		<>
			<FormStreamContextProvider>
				<ControlListContextProvider>
					<AsideWrapper>
						<FrameContextProvider>
							<FormHeader>
								<Header />
							</FormHeader>
							<div className="frame-wrapper">{children}</div>
						</FrameContextProvider>
					</AsideWrapper>
					<ControlPanelWrapper>
						<FormProvider {...methods}>
							<div className="controls-header">{activeControl.header}</div>
							<div className="controls-body">{activeControl.body}</div>
						</FormProvider>
					</ControlPanelWrapper>
				</ControlListContextProvider>
			</FormStreamContextProvider>
			<CustomSnackbar
				open={isSaving}
				message={
					<SaveMessageWrapper>
						<CircularProgress size={16} thickness={3.6} /> <Text>Saving</Text>
					</SaveMessageWrapper>
				}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			/>
		</>
	);
};

export default FrameLayout;
