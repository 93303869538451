import { IStateProps } from "utils/StateOf";
import { generateContext } from "utils/generateContext";

interface Values {
	controlDataStream?: any;
	isDirty: boolean;
}

export const initialState: IStateProps<Values> = {
	isDirty: false
};

export default generateContext<Values>();
