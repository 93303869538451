import { useCallback, useContext, useMemo } from "react";

import { UserContext } from "../contexts";

import { UserApiService } from "../services";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUser = () => {
	const context = useContext(UserContext);

	const setState = context.setState;

	const api = useMemo(() => new UserApiService(), []);

	const loadUser = useCallback(async () => {
		const user = await api.getUser();
		setState(ctx => ({
			...ctx,
			user
		}));
	}, [api, setState]);

	return {
		...context,
		loadUser
	};
};

export default useUser;
