import { IStateProps } from "utils/StateOf";
import { generateContext } from "utils/generateContext";

import { UserModel } from "../../types";

interface Values {
	user?: UserModel;
}

export const initialState: IStateProps<Values> = {
	user: undefined
};

export default generateContext<Values>();
