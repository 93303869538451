import { bind } from "decko";

import BaseApi from "shared/services/api/BaseApi";
import HttpActions from "shared/services/api/core/HttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { API_URLS } from "../../constants";

import { ApplicationModel } from "../types";

// @injectable()
export default class UserApiService extends BaseApi {
	constructor() {
		super(new HttpActions([]));
	}

	@bind
	public async getApp(): Promise<ApplicationModel> {
		return BaseConverter.convert<ApplicationModel>(await this.actions.get(API_URLS.APPLICATION.GET_APP_DETAILS));
	}
}
