import React, { useEffect, useMemo } from "react";

import { Button, Icon, createStyles, makeStyles } from "@makeen.io/material-ui-kit";
import { IconButton } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import theme from "theme/default";

import { useControlList, useForm, useFormStream } from "../../../Data";
import { getProps } from "../../helpers";
import { ControlBodyWrapper } from "../ElementsBody";

const useStyles = makeStyles(() =>
	createStyles({
		button: {
			marginLeft: "auto",
			padding: 0,
			"&:hover": {
				backgroundColor: "transparent"
			}
		}
	})
);

const ControlWrapper: React.FC = () => {
	const classes = useStyles();

	const {
		saveComponentData,
		discardComponentDataChanges,
		deleteFormControl,
		getFormControl,
		activeElementUID
	} = useForm();

	const { setControlStream } = useFormStream();

	const { handleSubmit, reset, watch, formState } = useFormContext();

	const { getControlInfo } = useControlList();

	const activeControl = useMemo(() => (activeElementUID ? getFormControl(activeElementUID) : undefined), [
		activeElementUID,
		getFormControl
	]);

	useEffect(() => {
		const controlData = activeControl?.controlData;
		const control = getControlInfo(activeControl?.controlKey);
		const formData = controlData || control?.initState;
		reset(formData);
	}, [getControlInfo, reset, activeControl]);

	useEffect(() => setControlStream(watch(), formState.isDirty), [formState.isDirty, setControlStream, watch]);

	const handleElementDiscard = () => {
		reset();
		discardComponentDataChanges();
	};

	const handleElementDelete = () => {
		deleteFormControl();
	};

	const onSubmit = data => saveComponentData(data);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<ControlBodyWrapper withBorder noPadding={activeControl?.controlKey === "c_textbox"}>
				{activeControl && getProps(activeControl.controlKey)}
			</ControlBodyWrapper>
			<ControlBodyWrapper layout="flex">
				<Button type="submit" buttonTheme="light" palette="basic" size="small" onClick={handleSubmit(onSubmit)}>
					Save & Close
				</Button>
				<Button buttonTheme="outline" onClick={handleElementDiscard} size="small">
					Discard Changes
				</Button>
				<IconButton className={classes.button} onClick={handleElementDelete}>
					<Icon fill={theme.palette.colors.basic[600]} name="dustbin" />
				</IconButton>
			</ControlBodyWrapper>
		</form>
	);
};

export default ControlWrapper;
