import { IStateProps } from "utils/StateOf";
import { generateContext } from "utils/generateContext";

import { FrameDevicesType, FrameOrientationsType, deviceType, orientationType } from "../../types";

interface TValues {
	frameDevice: FrameDevicesType;
	frameOrientation: FrameOrientationsType;
}

export const initialState: IStateProps<TValues> = {
	frameDevice: deviceType.tablet,
	frameOrientation: orientationType.portrait
};

export default generateContext<TValues>();
