export const MODULE_SLUG = "Auth";
export const MODULE_NAME = "Authentification";

export const API_URLS = {
	APPLICATION: {
		GET_APP_DETAILS: "/designer/appdetails"
	},
	USER: {
		GET_USER: "/user/getUser",
		POST_LOGOUT: "/user/logout"
	}
};
