import { AxiosResponse } from "axios";

import * as R from "ramda";

import baseConverter from "shared/services/converters/baseConverter";

import { FormDataGetResponseModel, FormDataModel, FormDataPostModel } from "../../types";

export const convertFormDataResult = (response: AxiosResponse<FormDataGetResponseModel>): FormDataModel => {
	const responseData = baseConverter.convert<FormDataGetResponseModel>(response);

	return {
		...R.omit(["DesignerJSON"], responseData),
		formControls: responseData.DesignerJSON ? JSON.parse(responseData.DesignerJSON) : {}
	};
};

export const convertFormDataForPost = (form: FormDataModel): FormDataPostModel => {
	const { ID, /*Version,*/ formControls } = form;

	return {
		PageId: ID,
		Version: 0,
		// Version: Version,
		Data: JSON.stringify(formControls)
	};
};
