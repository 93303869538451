import React from "react";

import { Route, Switch } from "react-router";

import IApp from "types/IApp";

import BaseLayout from "shared/templates/BaseLayout";

import { DesignerPage, ExternalLoginPage, LogOutPage } from "./Pages";

class FormDesignerApp implements IApp {
	getRoutes() {
		return (
			<>
				<Route exact key={"externalLogin"} path="/externalLogin/:token/form/:formId?" component={ExternalLoginPage} />
				<BaseLayout>
					<Switch>
						<Route exact key={"logout"} path="/logout" component={LogOutPage} />
						<Route exact key={"form"} path="/:formId?" component={DesignerPage} />
					</Switch>
				</BaseLayout>
			</>
		);
	}
}

export default FormDesignerApp;
