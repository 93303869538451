import { useContext, useMemo } from "react";

import { FrameContext } from "../contexts";
import { FrameDevicesType } from "../types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useFrame = () => {
	const context = useContext(FrameContext);

	const setState = context.setState;

	const methods = useMemo(
		() => ({
			changeDevice: (frameDevice: FrameDevicesType) => {
				setState(ctx => ({ ...ctx, frameDevice }));
			}
		}),
		[setState]
	);

	return useMemo(
		() => ({
			...context,
			...methods
		}),
		[context, methods]
	);
};

export default useFrame;
