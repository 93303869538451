import { bind } from "decko";

import BaseApi from "shared/services/api/BaseApi";
import HttpActions from "shared/services/api/core/HttpActions";

import BaseConverter from "shared/services/converters/baseConverter";

import { API_URLS } from "../../constants";
import { UserModel } from "../types";

// @injectable()
export default class UserApiService extends BaseApi {
	constructor() {
		super(new HttpActions([]));
	}

	@bind
	public async getUser() {
		return BaseConverter.convert<UserModel>(
			await this.actions.get(API_URLS.USER.GET_USER, null, { crossdomain: true })
		);
	}
}
