import React from "react";

import { Text } from "@makeen.io/material-ui-kit";
import { FormControl, FormLabel, InputAdornment, TextField } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { ValidationAdornmentIcon, ValidationStatus, debouncedValidateYtUrl } from "../../helpers";

const YoutubeProps = () => {
	const { register, watch } = useFormContext();

	const url = watch("weblink-url", "");

	const [urlValidationStatus, setUrlValidationStatus] = React.useState<ValidationStatus>("");
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const url = event.target.value;

		// clear validation status while typing
		setUrlValidationStatus("");

		if (url.length > 0) {
			debouncedValidateYtUrl(url, setUrlValidationStatus);
		}
	};

	const showError = url.length > 0 && urlValidationStatus === "invalid";

	return (
		<>
			<FormControl>
				<FormLabel>
					<Text variant="subtitle1">Enter YouTube URL</Text>
				</FormLabel>
				<TextField
					autoComplete="off"
					error={showError}
					helperText={showError && "Video with this link is not found"}
					id="youtube-url-input"
					inputRef={register}
					name="youtube-url"
					onChange={handleChange}
					placeholder="https://www.youtube.com/..."
					variant="outlined"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<ValidationAdornmentIcon status={urlValidationStatus} />
							</InputAdornment>
						)
					}}
				/>
			</FormControl>
		</>
	);
};

export default YoutubeProps;
