import { bind } from "decko";

import BaseApi from "shared/services/api/BaseApi";
import HttpActions from "shared/services/api/core/HttpActions";

import { clearJwt, setJwt } from "shared/services/api/core/jwt";

import { API_URLS } from "../../constants";

// @injectable()
export default class AuthApiService extends BaseApi {
	constructor() {
		super(new HttpActions([]));
	}

	@bind
	public async logIn(token: string) {
		setJwt(token);
	}

	@bind
	public async logOut() {
		await this.actions.post(API_URLS.USER.POST_LOGOUT, {});
		clearJwt();
	}
}
