import React, { useMemo } from "react";

import { Icon, Text, Tooltip } from "@makeen.io/material-ui-kit";
import {
	FormControl,
	FormLabel,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	createStyles,
	makeStyles
} from "@material-ui/core";

import { callingCountries } from "country-data";

import { Controller, useFormContext } from "react-hook-form";

import theme from "theme/default";

import { FlexItem } from "../../Containers";
import {
	ValidationAdornmentIcon,
	ValidationStatus,
	debouncedValidatePhoneNumber,
	debouncedValidateUrl
} from "../../helpers";

const useStyles = makeStyles(MuiTheme =>
	createStyles({
		// todo: move to theme
		formControl: {
			marginTop: MuiTheme.spacing(2)
		},
		menuItem: {
			color: theme.palette.colors.basic[800]
		},
		option: {
			"& .flag": {
				fontSize: 22,
				lineHeight: "16px",
				marginRight: 8
			},
			"& .no-flag": {
				fontSize: 14,
				lineHeight: "16px",
				marginRight: 8
			}
		},
		select: {
			height: 48
		},
		selectIcon: {
			pointerEvents: "none",
			position: "absolute",
			right: 8
		},
		svgSpan: {
			marginRight: 9,
			"& svg": {
				verticalAlign: "middle"
			}
		}
	})
);

const ButtonProps = () => {
	const classes = useStyles();

	const { register, watch, control } = useFormContext();

	const action = watch("button-action");
	const url = watch("button-url", "");
	const phonePrefix = watch("button-phone-prefix", "US");
	const phone = watch("button-phone-number", "");

	const [urlValidationStatus, setUrlValidationStatus] = React.useState<ValidationStatus>("");
	const [phoneValidationStatus, setPhoneValidationStatus] = React.useState<ValidationStatus>("");

	const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const url = event.target.value;

		// clear validation status while typing
		setUrlValidationStatus("");

		if (url.length > 0) {
			debouncedValidateUrl(url, setUrlValidationStatus);
		}
	};

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const number = event.target.value;

		// clear validation status while typing
		setPhoneValidationStatus("");

		if (number.length > 0) {
			debouncedValidatePhoneNumber(number, phonePrefix, setPhoneValidationStatus);
		}
	};

	const showWebError = url.length > 0 && urlValidationStatus === "invalid";
	const showPhoneError = phone.length > 0 && phoneValidationStatus === "invalid";

	const countries: {
		alpha2: string;
		flag: string; // UTF emoji
		name: string;
		numericPrefix: number;
		prefix: string;
	}[] = useMemo(
		() =>
			callingCountries.all
				.reduce((result, { alpha2, countryCallingCodes, emoji, name }) => {
					const isWindows = navigator.platform.toLowerCase().includes("win");

					if (alpha2 && emoji) {
						result.push({
							alpha2,
							// This is due to issue that Windows does not support emoji flags.
							flag: !isWindows && emoji,
							name,
							numericPrefix: countryCallingCodes[0].replace(/\D+/g, ""),
							prefix: countryCallingCodes[0]
						});
					}
					return result;
				}, [])
				.sort((a, b) => a.numericPrefix - b.numericPrefix), // sort by numeric prefix,
		[]
	);

	const renderPhonePrefixOptions = useMemo(
		() =>
			countries.map(({ alpha2, flag, name, prefix }) => (
				<MenuItem className={`prefix-${alpha2} ${classes.menuItem}`} key={alpha2} value={alpha2}>
					<Tooltip text={name}>
						<FlexItem className={classes.option} wrap={false}>
							{flag ? <span className="flag">{flag}</span> : <span className="no-flag">{alpha2}</span>}

							<span className="prefix">
								<Text variant="subtitle2">{prefix}</Text>
							</span>
						</FlexItem>
					</Tooltip>
				</MenuItem>
			)),
		[countries, classes]
	);

	const placeholder = {
		web: "Visit the Website",
		tel: "Call",
		sms: "Send SMS"
	};

	return (
		<>
			<FormControl className={classes.formControl}>
				<FormLabel>
					<FlexItem>
						<Text variant="subtitle1" style={{ marginRight: 10 }}>
							Action
						</Text>
						<Tooltip text="Choose an action that will be associated with this button">
							<Icon fill={theme.palette.colors.basic[500]} group="filled" name="info-circle" height={20} width={20} />
						</Tooltip>
					</FlexItem>
				</FormLabel>
				<Controller
					as={Select}
					control={control}
					id="button-action"
					name="button-action"
					defaultValue={""}
					variant="outlined"
					IconComponent={() => (
						<span className={classes.selectIcon}>
							<Icon group="filled" name="chevron-down" height={16} width={16} />
						</span>
					)}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left"
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left"
						},
						getContentAnchorEl: null
					}}
				>
					<MenuItem className="menuItem" value="">
						<Text>Select an option</Text>
					</MenuItem>
					<MenuItem className={classes.menuItem} value="web">
						<FlexItem>
							<span className={classes.svgSpan}>
								<Icon fill={theme.palette.colors.basic[600]} group="linear" name="globe" height={18} width={18} />
							</span>
							<Text>Open a web address</Text>
						</FlexItem>
					</MenuItem>
					<MenuItem className={classes.menuItem} value="tel">
						<FlexItem>
							<span className={classes.svgSpan}>
								<Icon fill={theme.palette.colors.basic[600]} group="filled" name="phone" height={18} width={18} />
							</span>
							<Text>Make a phone call</Text>
						</FlexItem>
					</MenuItem>
					<MenuItem className={classes.menuItem} value="sms">
						<FlexItem>
							<span className={classes.svgSpan}>
								<Icon fill={theme.palette.colors.basic[600]} group="filled" name="comment" height={18} width={18} />
							</span>
							<Text>Send an SMS</Text>
						</FlexItem>
					</MenuItem>
				</Controller>
			</FormControl>
			{action === "web" && (
				<>
					<FormControl className={classes.formControl}>
						<FormLabel>
							<Text variant="subtitle1">Web address</Text>
						</FormLabel>
						<TextField
							inputRef={register}
							autoComplete="off"
							error={showWebError}
							helperText={showWebError && "Web address is invalid"}
							id="button-url-input"
							name="button-url"
							onChange={handleUrlChange}
							placeholder="https://"
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<ValidationAdornmentIcon status={urlValidationStatus} />
									</InputAdornment>
								)
							}}
						/>
					</FormControl>
				</>
			)}
			{(action === "tel" || action === "sms") && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Phone number</Text>
					</FormLabel>

					<FlexItem wrap={false} yAlign={"flex-start"}>
						<Controller
							as={Select}
							className={classes.select}
							control={control}
							defaultValue={"US"}
							id="button-phone-prefix-select"
							name="button-phone-prefix"
							style={{ marginRight: 11 }}
							variant="outlined"
						>
							{renderPhonePrefixOptions}
						</Controller>

						<TextField
							inputRef={register}
							autoComplete="off"
							error={showPhoneError}
							helperText={showPhoneError && "Phone number is invalid"}
							id="button-phone-number-input"
							name="button-phone-number"
							onChange={handlePhoneChange}
							placeholder="323 669 7654"
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<ValidationAdornmentIcon status={phoneValidationStatus} />
									</InputAdornment>
								)
							}}
						/>
					</FlexItem>
				</FormControl>
			)}
			{action === "sms" && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<FlexItem>
							<Text variant="subtitle1" style={{ marginRight: 10 }}>
								Enter a message
							</Text>
							<Tooltip text="Enter a default message that end-users will be sending">
								<Icon fill={theme.palette.colors.basic[500]} group="filled" name="info-circle" height={20} width={20} />
							</Tooltip>
						</FlexItem>
					</FormLabel>

					<TextField
						inputRef={register}
						id="button-sms-message-input"
						multiline
						name="button-sms-message-input"
						placeholder="Type here..."
						rows={2}
						rowsMax={4}
						variant="outlined"
					/>
				</FormControl>
			)}
			<FormControl className={classes.formControl}>
				<FormLabel>
					<Text variant="subtitle1">Button text</Text>
				</FormLabel>

				<TextField
					inputRef={register}
					id="button-text-input"
					name={"button-text"}
					placeholder={placeholder[action]}
					variant="outlined"
				/>
			</FormControl>
		</>
	);
};

export default ButtonProps;
