import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AccessDenied, NotFound } from "shared/Errorpages";

import AppWrapper from "./AppWrapper";

import IApp from "../types/IApp";

const PREFIX = "/";

const routes = (modules: IApp[]) => (
	<AppWrapper>
		<BrowserRouter>
			<Route key="root" path={PREFIX}>
				<Switch>
					<Route key={"AccessDenied"} path="/AccessDenied" exact component={AccessDenied} />
					{modules.map(({ getRoutes }) => (getRoutes ? getRoutes() : null))}
					<Route key={"NotFound"} path="*" component={NotFound} />
				</Switch>
			</Route>
		</BrowserRouter>
	</AppWrapper>
);

export default routes;
