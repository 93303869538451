import { FormDesignerApp } from "apps";
import IApp from "types/IApp";

export default (): { apps: IApp[] } => {
	const formDesignerApp = new FormDesignerApp();

	return {
		apps: [formDesignerApp]
	};
};
