import React, { SetStateAction } from "react";

import { Button, Icon, Text, Tooltip } from "@makeen.io/material-ui-kit";
import {
	FormControl,
	FormLabel,
	IconButton,
	MenuItem,
	Select,
	Switch,
	TextField,
	createStyles,
	makeStyles
} from "@material-ui/core";

import theme from "theme/default";

import { FlexItem } from "../../Containers";

const useStyles = makeStyles(MuiTheme =>
	createStyles({
		menuItem: {
			color: theme.palette.colors.basic[800]
		},
		columnButton: {
			background: "transparent",
			border: `1px solid ${theme.palette.colors.basic[400]}`,
			borderRadius: 4,
			cursor: "pointer",
			margin: MuiTheme.spacing(1),
			outline: "none",
			padding: "0 5px"
		},
		activeColumnButton: {
			borderColor: theme.palette.colors.basic[700]
		}
	})
);

const Options = ({ items, setItems }: { items: string[]; setItems: SetStateAction<any> }) => {
	const handleChange = (index, value) => setItems([...items.slice(0, index), value, ...items.slice(index + 1)]);
	const handleDelete = index => setItems([...items.slice(0, index), ...items.slice(index + 1)]);
	const handleAdd = () => setItems([...items, ""]);

	return (
		<>
			{items.map((item, index) => (
				<FlexItem key={index} wrap={false} style={{ marginBottom: 8 }}>
					<TextField
						autoFocus={index === items.length - 1}
						onChange={event => handleChange(index, event.target.value)}
						value={item}
						variant="outlined"
					/>
					<IconButton
						aria-label="delete"
						data-index={index}
						style={{ marginLeft: 9, marginRight: 9, padding: 9 }}
						onClick={() => handleDelete(index)}
					>
						<Icon fill={theme.palette.colors.basic[600]} group="filled" name="close-circle" />
					</IconButton>
					<IconButton style={{ cursor: "grab", marginLeft: -9, marginRight: -9, padding: 9 }}>
						<Icon fill={theme.palette.colors.basic[600]} group="filled" name="reorder" />
					</IconButton>
				</FlexItem>
			))}
			<Button
				buttonTheme="ghost"
				leftIcon={<Icon fill={theme.palette.colors.basic[600]} group="color" name="plus-oval" />}
				onClick={handleAdd}
				palette="control"
			>
				<Text>Add option</Text>
			</Button>
		</>
	);
};

const Columns = ({ count, setCount }: { count: number; setCount: SetStateAction<any> }) => {
	const changeColumnLayout = (columns: number): void => setCount(columns);
	const classes = useStyles();

	return (
		<FlexItem style={{ marginLeft: -8, marginRight: -8 }}>
			<button
				className={`${classes.columnButton} ${count === 1 && classes.activeColumnButton}`}
				onClick={event => {
					event.preventDefault();
					changeColumnLayout(1);
				}}
			>
				<Tooltip text="1 column layout">
					<Icon
						fill={count === 1 ? "" : theme.palette.colors.basic[400]}
						group="color"
						name="layout-1col"
						height={54}
						width={54}
						viewBox="0 0 54 54"
					/>
				</Tooltip>
			</button>
			<button
				className={`${classes.columnButton} ${count === 2 && classes.activeColumnButton}`}
				onClick={event => {
					event.preventDefault();
					changeColumnLayout(2);
				}}
			>
				<Tooltip text={"2 column layout"}>
					<Icon
						fill={count === 2 ? "" : theme.palette.colors.basic[400]}
						group="color"
						name="layout-2col"
						height={54}
						width={54}
						viewBox="0 0 54 54"
					/>
				</Tooltip>
			</button>
			<button
				className={`${classes.columnButton} ${count === 3 && classes.activeColumnButton}`}
				onClick={event => {
					event.preventDefault();
					changeColumnLayout(3);
				}}
			>
				<Tooltip text={"3 column layout"}>
					<Icon
						fill={count === 3 ? "" : theme.palette.colors.basic[400]}
						group="color"
						name="layout-3col"
						height={54}
						width={54}
						viewBox="0 0 54 54"
					/>
				</Tooltip>
			</button>
		</FlexItem>
	);
};

const placeholders = {
	buttonText: "Upload photo",
	questionText: "Type question text here..."
};

const questionTypes = [
	{
		name: "Multiple choice",
		value: "multiple"
	},
	{
		name: "Free text",
		value: "text"
	},
	{
		name: "Yes / No",
		value: "boolean"
	},
	{
		name: "Dropdown",
		value: "dropdown"
	},
	{
		name: "Autofill",
		value: "autofill"
	}
];

const lists = [
	{
		name: "List of countries",
		value: "countries"
	},
	{
		name: "List of colors",
		value: "colors"
	}
];

const QuestionProps = () => {
	const classes = useStyles();
	const [questionType, setQuestionType] = React.useState("multiple");
	const [answers, setAnswers] = React.useState([""]);
	const [switchState, setSwitchState] = React.useState({
		multipleSelection: false,
		selectFromList: false,
		selectFromOptions: true,
		userCanAddOptions: false
	});
	const [yesNo, setYesNo] = React.useState({
		yes: "Yes",
		no: "No"
	});
	const [columns, setColumns] = React.useState(1);
	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
	};
	const handleQuestionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setQuestionType(event.target.value as string);
	};
	const handleYesNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setYesNo({ ...yesNo, [event.target.name]: event.target.value });
	};

	return (
		<>
			<FormControl>
				<FormLabel>
					<Text variant="subtitle1">Question Text</Text>
				</FormLabel>
				<TextField
					id="question-text-input"
					multiline
					placeholder={placeholders.questionText}
					rows={2}
					rowsMax={4}
					variant="outlined"
				/>
			</FormControl>

			<FormControl>
				<FormLabel>
					<Text variant="subtitle1">Question type</Text>
				</FormLabel>
				<Select id="question-type-select" value={questionType} variant="outlined" onChange={handleQuestionTypeChange}>
					{questionTypes.map((item, index) => (
						<MenuItem className={classes.menuItem} key={index} value={item.value}>
							{item.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{questionType === "dropdown" && (
				<FormControl>
					<FlexItem>
						<FormLabel>
							<Text variant="subtitle1">Allow user to choose from options below</Text>
						</FormLabel>
						<div style={{ marginLeft: "auto" }}>
							<Switch
								checked={switchState.selectFromOptions}
								id="question-allow-selection-switch"
								onChange={handleSwitchChange}
								color="primary"
								name="selectFromOptions"
								inputProps={{ "aria-label": "primary checkbox" }}
							/>
						</div>
					</FlexItem>
				</FormControl>
			)}

			{(questionType === "multiple" ||
				(questionType === "dropdown" && switchState.selectFromOptions) ||
				questionType === "autofill") && (
				<>
					<FormControl>
						<FormLabel>
							<Text variant="subtitle1">Answer options</Text>
						</FormLabel>
					</FormControl>
					<Options items={answers} setItems={setAnswers} />
				</>
			)}

			{questionType === "multiple" && (
				<>
					<FormControl>
						<FlexItem>
							<FormLabel>
								<Text variant="subtitle1">Allow user to select multiple values</Text>
							</FormLabel>
							<div style={{ marginLeft: "auto" }}>
								<Switch
									checked={switchState.multipleSelection}
									id="question-multiple-selection-switch"
									onChange={handleSwitchChange}
									color="primary"
									name="multipleSelection"
									inputProps={{ "aria-label": "primary checkbox" }}
								/>
							</div>
						</FlexItem>
					</FormControl>

					<FormControl>
						<FormLabel>
							<Text variant="subtitle1">Columns</Text>
						</FormLabel>
						<Columns count={columns} setCount={setColumns} />
					</FormControl>
				</>
			)}

			{(questionType === "dropdown" || questionType === "autofill") && (
				<>
					<FormControl>
						<FlexItem>
							<FormLabel>
								<Text variant="subtitle1">Allow users to choose from a list</Text>
							</FormLabel>
							<div style={{ marginLeft: "auto" }}>
								<Switch
									checked={switchState.selectFromList}
									id="question-selection-list-switch"
									onChange={handleSwitchChange}
									color="primary"
									name="selectFromList"
									inputProps={{ "aria-label": "primary checkbox" }}
								/>
							</div>
						</FlexItem>
					</FormControl>

					{switchState.selectFromList && (
						<FormControl>
							<Select id="options-list-select" variant="outlined">
								{lists.map((item, index) => (
									<MenuItem className={classes.menuItem} key={index} value={item.value}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					<FormControl>
						<FlexItem>
							<FormLabel>
								<Text variant="subtitle1">Allow user to select multiple values</Text>
							</FormLabel>
							<div style={{ marginLeft: "auto" }}>
								<Switch
									checked={switchState.multipleSelection}
									id="question-selection-multiple-switch"
									onChange={handleSwitchChange}
									color="primary"
									name="multipleSelection"
									inputProps={{ "aria-label": "primary checkbox" }}
								/>
							</div>
						</FlexItem>
					</FormControl>
					<FormControl>
						<FlexItem>
							<FormLabel>
								<Text variant="subtitle1">Allow users to add their own options</Text>
							</FormLabel>
							<div style={{ marginLeft: "auto" }}>
								<Switch
									checked={switchState.userCanAddOptions}
									id="question-selection-custom-switch"
									onChange={handleSwitchChange}
									color="primary"
									name="userCanAddOptions"
									inputProps={{ "aria-label": "primary checkbox" }}
								/>
							</div>
						</FlexItem>
					</FormControl>
				</>
			)}

			{questionType === "boolean" && (
				<FormControl>
					<FormLabel>
						<Text variant="subtitle1">Answer options</Text>
					</FormLabel>
					<FormControl style={{ marginBottom: 8 }}>
						<FlexItem>
							<Icon fill="green" group="filled" name="check-circle" />
							<TextField
								id="yes-text-input"
								name="yes"
								value={yesNo.yes}
								placeholder="Yes"
								onChange={handleYesNoChange}
								variant="outlined"
								style={{ marginLeft: 9, width: 80 }}
							/>
						</FlexItem>
					</FormControl>
					<FormControl>
						<FlexItem>
							<Icon fill="red" group="filled" name="close-circle" />
							<TextField
								id="no-text-input"
								name="no"
								value={yesNo.no}
								placeholder="No"
								onChange={handleYesNoChange}
								variant="outlined"
								style={{ marginLeft: 9, width: 80 }}
							/>
						</FlexItem>
					</FormControl>
				</FormControl>
			)}
		</>
	);
};

export default QuestionProps;
