import { useContext, useMemo } from "react";

import ApplicationContext from "../contexts/ApplicationContext/ApplicationContext";
import { ApplicationService } from "../services";
import EnvironmentType from "../types/EnvironmentType";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useApp = () => {
	const context = useContext(ApplicationContext);

	const setState = context.setState;

	const api = useMemo(() => new ApplicationService(), []);

	const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() as EnvironmentType;

	const methods = useMemo(
		() => ({
			loadApp: async () => {
				const application = await api.getApp();
				setState(ctx => ({ ...ctx, application: application }));
			}
		}),
		[api, setState]
	);

	return {
		...context,
		environment: context.environment || environment,
		...methods
	};
};

export default useApp;
