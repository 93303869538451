import { generateContext } from "utils/generateContext";

import { FormBodyViewsType, FormDataModel } from "../../types";

export interface FormContextValues {
	form: FormDataModel;
	formBodyView: FormBodyViewsType;
	activeElementUID?: string;
	isSaving: boolean;
}

export default generateContext<FormContextValues>();
