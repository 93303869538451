import React, { ReactNode, useMemo, useState } from "react";

import UserContext, { initialState } from "./UserContext";

interface Props {
	children: ReactNode;
}

const UserContextProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const context = useMemo(() => ({ ...state, setState }), [state, setState]);

	return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
