import React, { useEffect } from "react";

import { Avatar, ButtonMenu, ButtonMenuProps, Text } from "@makeen.io/material-ui-kit";
import { ExitToApp as ExitIcon, Person as PersonIcon } from "@material-ui/icons";
import { Link as ReactLink } from "react-router-dom";

import styled from "styled-components";

import theme from "theme/default";

import { useUser } from "../../Data/hooks";

const StyledButtonMenu = styled(ButtonMenu)<ButtonMenuProps>`
	background-color: transparent;
	padding: 0;

	&:hover {
		background-color: transparent;
	}

	.user-name {
		color: ${theme.palette.colors.basic[800]};
		padding: 10px;
	}

	li {
		border-top: 1px solid ${theme.palette.colors.basic[300]};
	}
`;

const Link = styled(ReactLink)`
	color: inherit;
	text-decoration: none !important;
`;

const MenuItem = styled.span`
	display: flex;

	svg + .menu-item-label {
		margin-left: 10px;
	}
`;

const UserAvatar = () => {
	const { user, loadUser } = useUser();

	const userMenuOptions = [
		{
			label: (
				<Link href="#" onClick={e => e.preventDefault()}>
					<MenuItem>
						<PersonIcon />
						<Text className="menu-item-label" variant="subtitle2">
							Manage Account
						</Text>
					</MenuItem>
				</Link>
			)
		},
		{
			label: (
				<Link to="/logout">
					<MenuItem>
						<ExitIcon color="error" />
						<Text className="menu-item-label" variant="subtitle2">
							Log Out
						</Text>
					</MenuItem>
				</Link>
			)
		}
	];

	useEffect(() => {
		if (!user) {
			(async () => await loadUser())();
		}
	}, [loadUser, user]);

	return (
		<div className="user">
			<StyledButtonMenu menuId="user-menu" options={userMenuOptions}>
				<Text className="user-name" variant="body2">
					{user?.DisplayName}
				</Text>
				<Avatar palette="infoWarning" title={user?.UserName || ""} />
			</StyledButtonMenu>
		</div>
	);
};

export default UserAvatar;
