import config from "config/appConfig";

import AuthApiService from "../services/AuthApiService";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAuth = () => {
	const api = new AuthApiService();

	return {
		logIn: token => api.logIn(token),
		logOut: async () => {
			await api.logOut();
			window.location.replace(config.CORE_API_URLS.LOGOUT);
		}
	};
};

export default useAuth;
