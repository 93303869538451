import React from "react";

import { Icon, Text } from "@makeen.io/material-ui-kit";
import { CircularProgress } from "@material-ui/core";
import parsePhoneNumber from "libphonenumber-js";
import { debounce } from "lodash";
import styled from "styled-components";

import {
	BarcodeControl,
	ButtonControl,
	DateTimeControl,
	DividerControl,
	ElementsBody,
	FileUploadControl,
	GeneralBody,
	LocationControl,
	PhotoUploadControl,
	SignatureControl,
	UserPickerControl
} from "./Components";

import {
	BarcodeProps,
	ButtonProps,
	DateTimeProps,
	DividerProps,
	FileUploadProps,
	LocationProps,
	PhotoUploadProps,
	QuestionProps,
	ReusableFormProps,
	SignatureProps,
	TextProps,
	UserPickerProps,
	WeblinkProps,
	YoutubeProps
} from "./Properties";

import theme from "../../../theme/default";
import { FormControlModel } from "../Data/types";
import ControlItemDataModel from "../Data/types/ControlItemDataModel";

type Props = React.ReactNode | string;

export const getProps = (key: string): Props => {
	switch (key) {
		case "c_textbox":
			return <TextProps />;
		case "c_question":
			return <QuestionProps />;
		case "c_divider":
			return <DividerProps />;
		case "c_image":
			return <>Control Properties Placeholder</>;
		case "c_signature":
			return <SignatureProps />;
		case "c_button":
			return <ButtonProps />;
		case "c_web":
			return <WeblinkProps />;
		case "c_form":
			return <ReusableFormProps />;
		case "c_photo":
			return <PhotoUploadProps />;
		case "c_file-upload":
			return <FileUploadProps />;
		case "c_date-time":
			return <DateTimeProps />;
		case "c_location":
			return <LocationProps />;
		case "c_profile":
			return <UserPickerProps />;
		case "c_barcode":
			return <BarcodeProps />;
		case "c_video":
			return <YoutubeProps />;
		default:
			return `Undefined control key: ${key}`;
	}
};

export type ValidationStatus = "" | "loading" | "valid" | "invalid";

export const ValidationAdornmentIcon = ({ status }: { status: ValidationStatus }) => {
	switch (status) {
		case "loading":
			return <CircularProgress size={24} />;
		case "valid":
			return <Icon fill={theme.palette.colors.success[600]} group="filled" name="check-circle" />;
		case "invalid":
			return <Icon fill={theme.palette.colors.danger[500]} group="filled" name="exclamation-triangle" />;
		default:
			return null;
	}
};

type ControlPlaceholderProps = {
	control: ControlItemDataModel;
	padding?: number;
};

const ControlPlaceholder = styled(({ control, ...rest }: ControlPlaceholderProps) => {
	const { controlName, controlIconGroup, controlIconName } = control;
	return (
		<div {...rest}>
			<Icon fill={theme.palette.colors.basic[600]} group={controlIconGroup} name={controlIconName} />
			<Text variant="subtitle2">{controlName}</Text>
		</div>
	);
})`
	background-color: ${theme.palette.colors.basic[200]};
	color: ${theme.palette.colors.basic[600]};
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	padding: ${({ padding }) => (padding ? padding : 16)}px 16px;
	user-select: none;
`;

interface GetControlResult {
	hasBackground: boolean;
	component: React.ReactFragment;
}

export const getControl = (
	key: string,
	getControlInfo: (k: string) => ControlItemDataModel | undefined,
	isActive: boolean,
	controlUid: string,
	controlDataStream: any,
	getFormControl: (cUid: string) => FormControlModel | undefined
): GetControlResult => {
	const control: ControlItemDataModel = getControlInfo(key) || ({} as ControlItemDataModel);
	const formControl = getFormControl(controlUid);

	const data = (isActive && controlDataStream) || formControl?.controlData;
	const propsList = Object.keys(control.initState);

	const controlProps: ControlItemDataModel & {
		padding?: number | string;
	} = {
		...control,
		padding: 11
	};
	switch (key) {
		case "c_textbox":
			return {
				hasBackground: true,
				component: <ControlPlaceholder control={controlProps} />
			};
		case "c_question":
			return {
				hasBackground: true,
				component: <ControlPlaceholder control={controlProps} />
			};
		case "c_divider":
			return {
				hasBackground: false,
				component: <DividerControl data={data} />
			};
		case "c_image":
			return {
				hasBackground: true,
				component: <ControlPlaceholder control={controlProps} padding={88} />
			};
		case "c_signature":
			return {
				hasBackground: true,
				component: (
					<SignatureControl data={data} propsList={propsList}>
						Sign here
					</SignatureControl>
				)
			};
		case "c_button":
			return {
				hasBackground: true,
				component: (
					<ButtonControl data={data} propsList={propsList}>
						Button
					</ButtonControl>
				)
			};
		case "c_web":
			return {
				hasBackground: true,
				component: <ControlPlaceholder control={controlProps} />
			};
		case "c_form":
			return {
				hasBackground: true,
				component: <ControlPlaceholder control={controlProps} />
			};
		case "c_photo":
			return {
				hasBackground: true,
				component: (
					<PhotoUploadControl data={data} propsList={propsList}>
						Upload a photo
					</PhotoUploadControl>
				)
			};
		case "c_file-upload":
			return {
				hasBackground: true,
				component: (
					<FileUploadControl data={data} propsList={propsList}>
						Upload file
					</FileUploadControl>
				)
			};
		case "c_date-time":
			return {
				hasBackground: true,
				component: (
					<DateTimeControl data={data} propsList={propsList}>
						Select date
					</DateTimeControl>
				)
			};
		case "c_location":
			return {
				hasBackground: true,
				component: (
					<LocationControl data={data} propsList={propsList}>
						Select location
					</LocationControl>
				)
			};
		case "c_profile":
			return {
				hasBackground: true,
				component: (
					<UserPickerControl data={data} propsList={propsList}>
						Select a user
					</UserPickerControl>
				)
			};
		case "c_barcode":
			return {
				hasBackground: true,
				component: (
					<BarcodeControl data={data} propsList={propsList}>
						Scan the barcode
					</BarcodeControl>
				)
			};
		case "c_video":
			return {
				hasBackground: true,
				component: <ControlPlaceholder control={controlProps} padding={88} />
			};
		default:
			return {
				hasBackground: false,
				component: <Text>{`Undefined control key: ${key}`}</Text>
			};
	}
};

export const getFormViewComponent = formView => {
	switch (formView) {
		case "elements":
			return <ElementsBody />;
		case "general":
			return <GeneralBody />;
		default:
			return null;
	}
};

export const isValidUrlSchema = (string: string): boolean => {
	let url;

	try {
		url = new URL(string);
	} catch (e) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
};

export const isValidYoutubeUrl = (url: string): boolean => {
	const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
	const matches = url.match(pattern);
	return !!matches;
};

export const validateNetworkRequest = (url, setStatus) => {
	setStatus("loading");
	fetch(url, { mode: "no-cors" })
		.then(() => setStatus("valid"))
		.catch(() => setStatus("invalid"));
};

const validatePhoneNumber = (number, country, setStatus) => {
	const phoneNumber = parsePhoneNumber(number, country);

	if (phoneNumber?.isValid()) {
		setStatus("valid");
	} else {
		setStatus("invalid");
	}
};

const validateUrl = (url, setStatus) => {
	if (isValidUrlSchema(url)) {
		validateNetworkRequest(url, setStatus);
	} else {
		setStatus("invalid");
	}
};

const validateYtUrl = (url, setStatus) => {
	if (isValidYoutubeUrl(url)) {
		validateNetworkRequest(url, setStatus);
	} else {
		setStatus("invalid");
	}
};
const timeout = 1000;
export const debouncedValidatePhoneNumber = debounce(validatePhoneNumber, timeout);
export const debouncedValidateUrl = debounce(validateUrl, timeout);
export const debouncedValidateYtUrl = debounce(validateYtUrl, timeout);
