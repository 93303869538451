import React from "react";

import { Text } from "@makeen.io/material-ui-kit";
import { FormControl, FormLabel, Switch, TextField, createStyles, makeStyles } from "@material-ui/core";

import { Controller, useFormContext } from "react-hook-form";

import { FlexItem } from "../../Containers";

const useStyles = makeStyles(theme =>
	createStyles({
		// todo: move to theme
		formControl: {
			marginTop: theme.spacing(2)
		}
	})
);

const DateTimeProps = () => {
	const classes = useStyles();
	const { control, watch, register, setValue } = useFormContext();

	const watchDatePicker = watch("date-picker", true);
	const watchTimePicker = watch("time-picker", false);
	const watchShowDescription = watch("show-description", false);
	const watchShowButtonText = watch("show-button-text", false);

	const placeholder = {
		buttonText: "Select date",
		description: "Type description text here..."
	};

	return (
		<>
			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Date picker</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							color="primary"
							control={control}
							defaultValue={true}
							id="date-time-date-picker-switch"
							inputProps={{ "aria-label": "primary checkbox" }}
							name="date-picker"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch
									onBlur={onBlur}
									onChange={e => {
										if (watchDatePicker && !watchTimePicker) {
											setValue("time-picker", true);
											setValue("date-picker", false);
										} else {
											onChange(watchTimePicker ? e.target.checked : !e.target.checked);
										}
									}}
									checked={!!value}
									inputRef={ref}
								/>
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Time picker</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							color="primary"
							control={control}
							defaultValue={false}
							id="date-time-time-picker-switch"
							inputProps={{ "aria-label": "primary checkbox" }}
							name="time-picker"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch
									onBlur={onBlur}
									onChange={e => {
										if (!watchDatePicker && watchTimePicker) {
											setValue("date-picker", true);
											setValue("time-picker", false);
										} else {
											onChange(watchDatePicker ? e.target.checked : !e.target.checked);
										}
									}}
									checked={!!value}
									inputRef={ref}
								/>
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Add description</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							control={control}
							color="primary"
							id="date-time-description-switch"
							inputProps={{ "aria-label": "primary checkbox" }}
							name="show-description"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			{watchShowDescription && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Description</Text>
					</FormLabel>
					<TextField
						id="date-time-description-input"
						multiline
						name={"date-time-description"}
						placeholder={placeholder.description}
						rows={2}
						rowsMax={4}
						variant="outlined"
						inputRef={register}
					/>
				</FormControl>
			)}

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Customize button text</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							control={control}
							color="primary"
							id="date-time-button-text-switch"
							inputProps={{ "aria-label": "primary checkbox" }}
							name="show-button-text"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			{watchShowButtonText && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Button text</Text>
					</FormLabel>
					<TextField
						inputRef={register}
						id="date-time-button-text-input"
						name="date-time-button-text"
						placeholder={placeholder.buttonText}
						variant="outlined"
					/>
				</FormControl>
			)}
		</>
	);
};

export default DateTimeProps;
