import React, { ReactNode, useMemo, useState } from "react";

import ControlListContext, { initialState } from "./ControlListContext";

interface Props {
	children: ReactNode;
}

const ControlListContextProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const context = useMemo(() => ({ ...state, setState }), [state, setState]);

	return <ControlListContext.Provider value={context}>{children}</ControlListContext.Provider>;
};

export default ControlListContextProvider;
