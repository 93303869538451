import React from "react";

import { Icon, Text } from "@makeen.io/material-ui-kit";
import {
	FormControl,
	FormLabel,
	InputAdornment,
	MenuItem,
	Select,
	Switch,
	TextField,
	createStyles,
	makeStyles
} from "@material-ui/core";

import { Controller, useFormContext } from "react-hook-form";

import theme from "theme/default";

import { FlexItem } from "../../Containers";

const useStyles = makeStyles(MuiTheme =>
	createStyles({
		// todo: move to theme
		formControl: {
			marginTop: MuiTheme.spacing(2)
		},
		menuItem: {
			color: theme.palette.colors.basic[800]
		},
		textField: {
			backgroundColor: theme.palette.colors.basic[300]
		}
	})
);

const UserPickerProps = () => {
	const classes = useStyles();

	const { watch, register, control } = useFormContext();

	const watchUserSource = watch("user-picker-user-source", "all");
	const watchShowDescription = watch("show-description", false);
	const watchShowButtonText = watch("show-button-text", false);

	const placeholder = {
		buttonText: "Select a user",
		description: "Type description text here...",
		selectUsers: "Type a Name or Email Address"
	};

	const userSources = [
		{ value: "all", name: "All users" },
		{ value: "roles", name: "Roles" },
		{ value: "individual", name: "Individual Users" }
	];

	const userRoles = [
		{ value: "pm", name: "PMs" },
		{ value: "hr", name: "Hrs" },
		{ value: "dev", name: "Devs" }
	];

	return (
		<>
			<FormControl className={classes.formControl}>
				<FormLabel>
					<FlexItem>
						<Text variant="subtitle1">Allow selection from</Text>
					</FlexItem>
				</FormLabel>
				<Controller
					as={Select}
					control={control}
					id="button-action-select"
					name="user-picker-user-source"
					variant="outlined"
				>
					{userSources.map(({ name, value }) => (
						<MenuItem className={classes.menuItem} key={value} value={value}>
							{name}
						</MenuItem>
					))}
				</Controller>
			</FormControl>

			{watchUserSource === "roles" && (
				<FormControl className={classes.formControl}>
					<Controller as={Select} control={control} id="user-roles-select" multiple name="roles" variant="outlined">
						{userRoles.map(role => (
							<MenuItem key={role.value} value={role.value}>
								{role.name}
							</MenuItem>
						))}
					</Controller>
				</FormControl>
			)}

			{watchUserSource === "individual" && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Select Users</Text>
					</FormLabel>
					<TextField
						inputRef={register}
						className={classes.textField}
						id="select-users-input"
						multiline
						placeholder={placeholder.selectUsers}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon fill={theme.palette.colors.basic[600]} group="linear" name="search" height={15} />
								</InputAdornment>
							)
						}}
						variant="outlined"
					/>
				</FormControl>
			)}

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Allow to add multiple users</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							control={control}
							color="primary"
							inputProps={{ "aria-label": "primary checkbox" }}
							id="user-picker-multiple-selection"
							name="multiple-selection"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Add description</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							color="primary"
							inputProps={{ "aria-label": "primary checkbox" }}
							id="user-picker-show-description"
							name="show-description"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			{watchShowDescription && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Description</Text>
					</FormLabel>
					<TextField
						inputRef={register}
						id="user-picker-description-input"
						multiline
						name={"user-picker-description"}
						placeholder={placeholder.description}
						rows={2}
						rowsMax={4}
						variant="outlined"
					/>
				</FormControl>
			)}

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Customize button text</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							color="primary"
							id="user-picker-show-button-text"
							inputProps={{ "aria-label": "primary checkbox" }}
							name="show-button-text"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			{watchShowButtonText && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Button text</Text>
					</FormLabel>

					<TextField
						inputRef={register}
						id="user-picker-button-text-input"
						name="user-picker-button-text"
						placeholder={placeholder.buttonText}
						variant="outlined"
					/>
				</FormControl>
			)}
		</>
	);
};

export default UserPickerProps;
