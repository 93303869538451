import React from "react";

import { Divider as MukDivider } from "@makeen.io/material-ui-kit";
import * as R from "ramda";

interface Props {
	data: any;
}

const Divider: React.FC<Props> = ({ data }) => {
	const dividerStyle = R.prop("divider-style", data);

	return (
		<MukDivider borderStyle={dividerStyle} padding={24} style={{ borderRadius: dividerStyle === "dotted" ? 0 : 2 }} />
	);
};

export default Divider;
