import React from "react";

import { Icon, Button as MukButton, Text } from "@makeen.io/material-ui-kit";
import * as R from "ramda";
import styled from "styled-components";

import theme from "../../../../../theme/default";
import { FlexItem } from "../../Containers";

interface Props {
	data: any;
	children: React.ReactNode;
	propsList: string[];
}

type ControlDescriptionProps = {
	text: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ControlDescription = styled(({ text, ...props }: ControlDescriptionProps) => (
	<div {...props}>
		<Text variant="subtitle1">{text}</Text>
	</div>
))`
	margin-bottom: 15px;
`;

const Barcode: React.FC<Props> = ({ data, propsList, children }) => {
	const [barcodeButtonText, barcodeDescription, showButtonText, showDescription] = R.props(propsList, data);

	const buttonStyles = `
		&, &:hover {
			background-color: ${theme.palette.colors.basic[600]};
		}
	`;

	return (
		<>
			{showDescription && barcodeDescription && <ControlDescription text={barcodeDescription} />}
			<FlexItem direction="column">
				<MukButton customStyle={() => buttonStyles} leftIcon={<Icon fill="#fff" group="filled" name="barcode" />}>
					{showButtonText && barcodeButtonText ? barcodeButtonText : children}
				</MukButton>
			</FlexItem>
		</>
	);
};

export default Barcode;
