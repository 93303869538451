import React, { ReactNode } from "react";

import { Button, Icon, Tooltip } from "@makeen.io/material-ui-kit";

import { Dialog, DialogContent, DialogTitle, IconButton, Link as MuiLink, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Close as CloseIcon } from "@material-ui/icons";

import styled from "styled-components";

import { data, view } from "modules/App";
import theme from "theme/default";

const PageWrapper = styled.div`
	height: 100vh;
`;

const TopBar = styled.header`
	backdrop-filter: blur(20px);
	box-shadow: 0 0.5px 0 0 ${theme.palette.colors.basic[500]};
	background-color: rgba(255, 255, 255, 0.94);
	padding: 12px 0;
	display: flex;

	.left-side {
		display: flex;
		padding-left: 24px;
		width: 100%;
	}

	.right-side {
		display: flex;
		justify-content: flex-end;
		margin-left: auto;
		min-width: 480px;
		padding-right: 24px;
	}

	.brand {
		display: flex;

		&-meta {
			margin-left: 12px;
		}
	}

	.actions {
		margin-left: auto;
	}

	.help {
	}

	button {
		margin: auto auto auto 8px;
	}
`;

const DialogSlideTransition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const DialogCloseButton = styled(IconButton)`
	position: absolute;
	right: ${theme.spacing(1)}px;
	top: ${theme.spacing(1)}px;
`;

const Main = styled.main`
	background-color: ${theme.palette.colors.basic[200]};
	min-height: 100%;
	display: flex;
	justify-content: space-between;
`;

const StyledIconButton = styled(IconButton)`
	padding: ${theme.spacing(1)}px;
`;

const Link = styled(MuiLink)`
	color: inherit;
	text-decoration: none !important;
`;

const ControlBodyWrapper: any = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 480px;
	padding: 0;
`;

ControlBodyWrapper.List = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	justify-content: flex-start;
	padding: ${theme.spacing(2)}px;
`;

ControlBodyWrapper.InfoText = styled.div`
	color: #8f9bb3;
	height: 50px;
	margin: 40px;
	text-align: center;
`;

interface BaseLayoutProps {
	children: ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
	const [dialogOpen, setDialogOpen] = React.useState(false);

	const handleClickOpen = () => {
		setDialogOpen(true);
	};

	const handleClose = () => {
		setDialogOpen(false);
	};

	return (
		<PageWrapper>
			<TopBar>
				<div className="left-side">
					<data.ApplicationContextProvider>
						<view.ApplicationInfo />
					</data.ApplicationContextProvider>
					<div className="actions">
						<Tooltip text="Revert the last change">
							<Button
								buttonTheme="outline"
								disabled
								rightIcon={<Icon group="filled" name="undo" />}
								size="medium"
								square
							/>
						</Tooltip>
						<Tooltip text="Redo the last change">
							<Button
								buttonTheme="outline"
								disabled
								rightIcon={<Icon group="filled" name="redo" />}
								size="medium"
								square
							/>
						</Tooltip>
						<Button>Publish</Button>
					</div>
				</div>
				<div className="right-side">
					<div className="help">
						<Tooltip text="Create a support ticket and our agents will help you with your problem">
							<Link onClick={handleClickOpen}>
								<StyledIconButton>
									<Icon fill={theme.palette.colors.basic[500]} group="filled" name="support" />
								</StyledIconButton>
							</Link>
						</Tooltip>
						<Dialog keepMounted onClose={handleClose} open={dialogOpen} TransitionComponent={DialogSlideTransition}>
							<DialogTitle>{"Dialog Title" /* todo: update support dialog title*/}</DialogTitle>
							<DialogContent style={{ minWidth: 500 }}>
								<iframe
									title="Feedback Form"
									className="freshwidget-embedded-form"
									id="freshwidget-embedded-form"
									src="https://support.makeen.io/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No"
									scrolling="no"
									height="500px"
									width="100%"
									frameBorder="0"
								/>
								<DialogCloseButton aria-label="close" onClick={handleClose}>
									<CloseIcon />
								</DialogCloseButton>
							</DialogContent>
						</Dialog>
						<Tooltip text="Learn more">
							<Link href="https://makeen.freshdesk.com/support/home" rel="noreferrer" target="_blank">
								<StyledIconButton>
									<Icon fill={theme.palette.colors.basic[500]} group="filled" name="question" />
								</StyledIconButton>
							</Link>
						</Tooltip>
					</div>
					<data.UserContextProvider>
						<view.UserAvatar />
					</data.UserContextProvider>
				</div>
			</TopBar>
			<Main>{children}</Main>
		</PageWrapper>
	);
};

export default BaseLayout;
