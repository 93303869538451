import React, { useEffect, useState } from "react";

import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

import FrameBody from "./FrameBody";

import { useForm } from "../../../Data";
import { FrameLayout } from "../../Components";

export interface FrameProps {
	formId: string | number;
}

const Main: React.FC<FrameProps> = ({ formId }) => {
	const { loadForm, addComponent, reorderComponents } = useForm();

	useEffect(() => {
		loadForm(formId);
	}, [formId, loadForm]);

	const [hover, setHover] = useState<boolean>(false);

	const onDragEnd = (result: DropResult): void => {
		setHover(false);

		const { source, destination, draggableId } = result;

		if (!destination) return;

		if (result.draggableId.toString().includes("frame_")) {
			reorderComponents(source.index, destination.index);
		} else {
			addComponent(draggableId, destination.index);
		}
	};

	const onDragStart = () => {
		setHover(true);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
			<FrameLayout>
				<Droppable droppableId="frame" type="element" direction="vertical">
					{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<FrameBody hover={hover} snapshot={snapshot} provided={provided} />
						</div>
					)}
				</Droppable>
			</FrameLayout>
		</DragDropContext>
	);
};

export default Main;
