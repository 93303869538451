import React from "react";

import { Text } from "@makeen.io/material-ui-kit";
import { FormControl, FormLabel, Switch, TextField, createStyles, makeStyles } from "@material-ui/core";

import { Controller, useFormContext } from "react-hook-form";

import { FlexItem } from "../../Containers";

const useStyles = makeStyles(theme =>
	createStyles({
		// todo: move to theme
		formControl: {
			marginTop: theme.spacing(2)
		}
	})
);

const FileUploadProps = () => {
	const classes = useStyles();
	const { watch, register, control } = useFormContext();

	const watchShowDescription = watch("show-description");
	const watchShowButtonText = watch("show-button-text");

	const placeholder = {
		buttonText: "Upload file",
		description: "Type description text here..."
	};

	return (
		<>
			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Add description</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							control={control}
							color="primary"
							id="file-upload-description-switch"
							inputProps={{ "aria-label": "primary checkbox" }}
							name="show-description"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			{watchShowDescription && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Description</Text>
					</FormLabel>
					<TextField
						inputRef={register}
						id="file-upload-description-input"
						multiline
						name={"file-upload-description"}
						placeholder={placeholder.description}
						rows={2}
						rowsMax={4}
						variant="outlined"
					/>
				</FormControl>
			)}

			<FormControl className={classes.formControl}>
				<FlexItem>
					<FormLabel>
						<Text variant="subtitle1">Customize button text</Text>
					</FormLabel>
					<div style={{ marginLeft: "auto" }}>
						<Controller
							type="checkbox"
							control={control}
							color="primary"
							id="button-text-switch"
							inputProps={{ "aria-label": "primary checkbox" }}
							name="show-button-text"
							render={({ onChange, onBlur, value, ref }) => (
								<Switch onBlur={onBlur} onChange={e => onChange(e.target.checked)} checked={!!value} ref={ref} />
							)}
						/>
					</div>
				</FlexItem>
			</FormControl>

			{watchShowButtonText && (
				<FormControl className={classes.formControl}>
					<FormLabel>
						<Text variant="subtitle1">Button text</Text>
					</FormLabel>
					<TextField
						inputRef={register}
						id="file-upload-button-text-input"
						name="file-upload-button-text"
						placeholder={placeholder.buttonText}
						variant="outlined"
					/>
				</FormControl>
			)}
		</>
	);
};

export default FileUploadProps;
