import React, { HTMLAttributes, useState } from "react";

import { QuestionCard as Card, Frame, Icon, Text } from "@makeen.io/material-ui-kit";
import {
	Draggable,
	DraggableProvidedDragHandleProps,
	DraggableProvidedDraggableProps,
	DroppableProvided,
	DroppableStateSnapshot
} from "react-beautiful-dnd";
import styled from "styled-components";

import theme from "theme/default";

import { useControlList, useForm, useFormStream, useFrame } from "../../../Data";
import { getControl } from "../../helpers";

const DroppableWrapper = styled.div`
	min-height: 100%;
	overflow-y: auto;
`;

const ElementWrapper = styled.div<
	React.HTMLAttributes<HTMLDivElement> & {
		isDragging: boolean;
		hasBackground: boolean;
		onMouseEnter: (event: MouseEvent) => void;
		onMouseLeave: (event: MouseEvent) => void;
	} & DraggableProvidedDraggableProps &
		DraggableProvidedDragHandleProps &
		any //todo: Fix any
>`
	border-radius: 4px;
	cursor: ${({ isDragging }) => (isDragging ? "move" : "pointer")};
	margin-bottom: 16px;
	padding: 0.1px 0; /* temporary padding fix for children with margin */
	position: relative;
	opacity: ${({ isDragging }) => (isDragging ? "0.5" : 1)};
	box-shadow: ${({ isDragging }) => (isDragging ? "0 2px 10px 0 rgba(0, 0, 0, 0.48)" : "")};

	${({ hasBackground }) =>
		hasBackground
			? `
		background: ${theme.palette.colors.basic[100]};
		border: 1px solid ${theme.palette.colors.basic[300]};
		padding: 24px;
		`
			: ""}
`;

const ElementDragIcon = styled(props => {
	return (
		<div {...props}>
			<Icon fill={theme.palette.colors.basic[600]} group="filled" name="reorder" />
		</div>
	);
})`
	display: flex;
	justify-content: center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	& > svg {
		cursor: grab;
	}
`;

const ElementBodyWrapper = styled.div`
	min-height: 10px;
	cursor: pointer;
`;

type ElementProps = {
	controlUID: string;
	hasBackground: boolean;
	isActive: boolean;
	isDragging: boolean;
	isRequireable: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

// eslint-disable-next-line react/display-name
const Element = React.forwardRef<any, ElementProps>((props, ref) => {
	const { children, hasBackground, isActive, isDragging, isRequireable, controlUID, ...rest } = props;
	const [displayIcon, setDisplayIcon] = useState(isActive);

	const { setComponentActive, deleteFormControl, duplicateControl, saveComponentData, activeElementUID } = useForm();
	const { controlDataStream, isDirty } = useFormStream();

	return (
		<ElementWrapper
			ref={ref}
			isDragging={isDragging}
			hasBackground={!isActive && hasBackground}
			onMouseEnter={() => setDisplayIcon(true)}
			onMouseLeave={() => setDisplayIcon(isActive)}
			{...rest}
		>
			{(displayIcon || isDragging) && <ElementDragIcon />}
			{isActive ? (
				<Card
					cardStyle={() => {
						return {
							border: `1px solid ${theme.palette.colors.primary[500]}`,
							boxShadow: "none",
							// I know, this is dirty, but...
							// Desperate times call for desperate measures
							"& .right-actions > div > div": {
								marginTop: -8,
								"& .label": {
									color: theme.palette.colors.basic[600],
									fontSize: "0.8125rem", // 13px
									fontWeight: 600,
									lineHeight: 1.85
								}
							}
						};
					}}
					isRequireable={isRequireable}
					onChangeRequired={value => {
						// todo: store control required state
						console.log(`Required state changed to ${value}`);
					}}
					onDelete={() => deleteFormControl()}
					onDuplicate={() => duplicateControl()}
					toolTipText={""}
					withToolTip={false}
					controlName="control"
				>
					{children}
				</Card>
			) : (
				<ElementBodyWrapper
					onClick={() => {
						if (activeElementUID && isDirty) {
							saveComponentData(controlDataStream, controlUID);
						} else {
							setComponentActive(controlUID);
						}
					}}
				>
					{children}
				</ElementBodyWrapper>
			)}
		</ElementWrapper>
	);
});

interface BlankFrameContentProps {
	isDragging: boolean;
}
const BlankFrameContent = styled(
	({ isDragging, ...rest }: Readonly<BlankFrameContentProps>): JSX.Element => (
		<div {...rest}>
			<Icon
				name="large-drag-drop"
				fill={isDragging ? theme.palette.frame.highlightBorderColor : theme.palette.frame.blankContentColor}
				height={76}
				width={76}
				viewBox="0 0 76 76"
			/>
			<Text variant="subtitle1">
				Drag the elements on the canvas
				<br />
				to create your form.
			</Text>
		</div>
	)
)<HTMLAttributes<HTMLDivElement> & { isDragging: boolean }>`
	color: ${({ isDragging }) =>
		isDragging ? theme.palette.frame.highlightBorderColor : theme.palette.frame.blankContentColor};
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	padding-top: 126px;
	text-align: center;

	svg {
		margin-bottom: 26px;
	}
`;

interface Props {
	hover: boolean;
	snapshot: DroppableStateSnapshot;
	provided: DroppableProvided;
}

const FrameBody: React.FC<Props> = ({ hover, snapshot, provided }) => {
	const {
		activeElementUID,
		form: { formControls }
	} = useForm();

	const { getControlInfo } = useControlList();
	const { getFormControl } = useForm();

	const { frameOrientation, frameDevice } = useFrame();
	const { controlDataStream } = useFormStream();

	return (
		<Frame orientation={frameOrientation} variant={frameDevice} highlight={hover} glow={snapshot.isDraggingOver}>
			<DroppableWrapper>
				{Array.isArray(formControls) && formControls.length ? (
					formControls.map(({ controlUID, controlKey }, i) => {
						const elementIsActive = activeElementUID === controlUID;
						const control = getControl(
							controlKey,
							getControlInfo,
							elementIsActive,
							controlUID,
							controlDataStream,
							getFormControl
						);
						const controlInfo = getControlInfo(controlKey);

						return (
							<Draggable key={controlUID} draggableId={controlUID} index={i}>
								{(p, s) => (
									<Element
										controlUID={controlUID}
										hasBackground={control?.hasBackground}
										ref={p.innerRef}
										isActive={elementIsActive}
										isDragging={s.isDragging}
										isRequireable={controlInfo?.isRequireable || false}
										{...p.draggableProps}
										{...p.dragHandleProps}
										key={controlUID}
									>
										{control?.component}
									</Element>
								)}
							</Draggable>
						);
					})
				) : (
					<BlankFrameContent isDragging={snapshot.isDraggingOver} />
				)}
				{provided.placeholder}
			</DroppableWrapper>
		</Frame>
	);
};

export default FrameBody;
