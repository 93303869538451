import { IStateProps } from "utils/StateOf";

import { FormContextValues } from "./FormContext";

import { FormDataModel } from "../../types";

const initialState: IStateProps<FormContextValues> = {
	form: {} as FormDataModel,
	formBodyView: "elements",
	isSaving: false
};

export default initialState;
