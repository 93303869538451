import React from "react";

import { Icon, Button as MukButton } from "@makeen.io/material-ui-kit";
import * as R from "ramda";

import theme from "theme/default";

import { FlexItem } from "../../Containers";

interface Props {
	children: React.ReactNode;
	data: any;
	propsList: string[];
}

const Button: React.FC<Props> = ({ data, propsList, children }) => {
	const defaultButtonText = {
		web: "Visit the Website",
		tel: "Call",
		sms: "Send SMS"
	};

	const buttonIcon = {
		web: { group: "linear", name: "globe" },
		tel: { group: "filled", name: "phone" },
		sms: { group: "filled", name: "comment" }
	};

	const [buttonText, buttonAction] = R.props(propsList, data);

	const buttonStyles = `
		&, &:hover {
			background-color: ${theme.palette.colors.basic[600]};
		}
	`;

	return (
		<FlexItem direction="column">
			<MukButton
				customStyle={() => buttonStyles}
				leftIcon={
					buttonAction && (
						<Icon fill="#fff" group={buttonIcon[buttonAction].group} name={buttonIcon[buttonAction].name} />
					)
				}
			>
				{buttonText || defaultButtonText[buttonAction] || children}
			</MukButton>
		</FlexItem>
	);
};

export default Button;
