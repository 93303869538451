import React from "react";

import { useParams } from "react-router";

import { data, view } from "modules/FormDesigner";

const DesignerPage = () => {
	const { formId } = useParams();

	return (
		<data.FormContextProvider>
			<view.Frame formId={formId} />
		</data.FormContextProvider>
	);
};

export default DesignerPage;
