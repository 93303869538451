import React from "react";

import { Icon, Divider as MukDivider, Text } from "@makeen.io/material-ui-kit";
import { FormControl, FormLabel, MenuItem, Select, createStyles, makeStyles } from "@material-ui/core";

import { Controller, useFormContext } from "react-hook-form";

import { FlexItem } from "../../Containers";

const useStyles = makeStyles(theme =>
	createStyles({
		// todo: move to theme
		formControl: {
			marginTop: theme.spacing(2)
		},
		menuItem: {
			"& .style": {
				width: 80
			},
			"& .preview": {
				width: "100%"
			}
		},
		selectIcon: {
			pointerEvents: "none",
			position: "absolute",
			right: 8
		}
	})
);

const dividerStyles = ["None", "Solid", "Dashed", "Dotted"];
type DividerStyles = "none" | "solid" | "dashed" | "dotted";

const DividerProps = () => {
	const classes = useStyles();
	const { control } = useFormContext();

	return (
		<>
			<FormControl className={classes.formControl}>
				<FormLabel>
					<Text variant="subtitle1" style={{ marginRight: 10 }}>
						Style
					</Text>
				</FormLabel>
				<Controller
					as={Select}
					control={control}
					defaultValue={"solid"}
					id="divider-style-select"
					name="divider-style"
					variant="outlined"
					IconComponent={() => (
						<span className={classes.selectIcon}>
							<Icon group="filled" name="chevron-down" height={16} width={16} />
						</span>
					)}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left"
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left"
						},
						getContentAnchorEl: null
					}}
				>
					{dividerStyles.map(style => (
						<MenuItem key={style} value={style.toLowerCase()}>
							<FlexItem className={classes.menuItem} wrap={false} style={{ width: "100%" }}>
								<span className="style">{style}</span>
								<div className="preview">
									<MukDivider
										borderStyle={style.toLowerCase() as DividerStyles}
										padding={0}
										style={{ borderRadius: style === "Dotted" ? 0 : 2 }}
									/>
								</div>
							</FlexItem>
						</MenuItem>
					))}
				</Controller>
			</FormControl>
		</>
	);
};

export default DividerProps;
