import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { bind } from "decko";

import ApiError from "./ApiError";

import { injectJwt } from "./jwt";

import { unAuthorizedInterceptor } from "../interceptors";

class HttpActions {
	private request: AxiosInstance;

	constructor(errorInterceptors: any[]) {
		errorInterceptors.push(unAuthorizedInterceptor);
		const config: AxiosRequestConfig = {
			baseURL: process.env.REACT_APP_API_URL,
			// timeout: 5000,
			withCredentials: true,
			validateStatus: status => status >= 200 && status < 300
		};

		this.request = axios.create(config);
		this.request.interceptors.response.use(
			response => response,
			err => {
				if (err.response) {
					const { status, data } = err.response;
					const apiErrorInstance = new ApiError(status, data.errors || [], data.message);
					errorInterceptors.forEach(f => f(apiErrorInstance));
					throw apiErrorInstance;
				} else {
					throw Error(err.message);
				}
			}
		);
	}

	@bind
	get(url, params?, options?) {
		return this.request.get(url, injectJwt({ params, ...options }));
	}

	@bind
	post(url, data, options?) {
		return this.request.post(url, data, injectJwt(options));
	}

	@bind
	delete(url, data?, params?, options?) {
		const config = { url, data, params, ...options };
		return this.request.delete(url, injectJwt(config));
	}

	@bind
	put(url, data, params?, options?) {
		return this.request.put(url, data, { params, ...injectJwt(options) });
	}

	@bind
	getBlob(url, options?) {
		return axios({
			url,
			method: "GET",
			responseType: "blob",
			...injectJwt(options)
		}).then(response => window.URL.createObjectURL(new Blob([response.data], { type: "text/csv;charset=utf-8;" })));
	}
}

export default HttpActions;
