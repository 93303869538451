import React from "react";

import styled from "styled-components";

type FlexItemProps = {
	direction?: "column" | "row";
	wrap?: boolean;
	xAlign?: string;
	yAlign?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const FlexItem = styled(({ direction, wrap, children, ...rest }: FlexItemProps) => {
	return <div {...rest}>{children}</div>;
})`
	display: flex;
	flex-flow: ${({ direction = "row", wrap = true }) => {
		let flow = "";

		flow += direction === "row" ? "row" : "column";
		flow += wrap ? " wrap" : " nowrap";

		return flow;
	}};
	align-items: ${({ yAlign = "center" }) => yAlign};
	justify-content: ${({ xAlign = "flex-start" }) => xAlign};
`;

export default FlexItem;
