import React, { useEffect } from "react";

import { Avatar, StatusLabel, Text, Tooltip } from "@makeen.io/material-ui-kit";

import { useApp } from "../../Data/hooks";
import { EnvironmentType } from "../../Data/types";

type EnvironmentProps = {
	type: EnvironmentType;
};

const Environment: React.FC<EnvironmentProps> = ({ type }) => {
	const color = {
		development: "danger",
		production: "success",
		staging: "warning"
	};

	const envType = type?.toLowerCase() as EnvironmentType;

	return <StatusLabel color={color[envType] as any} label={envType} upperCase />;
};

const ApplicationInfo = () => {
	const { application, environment, loadApp } = useApp();

	useEffect(() => {
		loadApp();
	}, [loadApp]);

	return (
		<div className="brand">
			<div className="brand-image">
				<Avatar
					palette="infoLight"
					variant="rounded"
					title={"Stark"}
					image={
						application?.AvatarBase64String ? `data:image/png;base64, ${application.AvatarBase64String}` : undefined
					}
				/>
			</div>
			<div className="brand-meta">
				<Text variant="h6" style={{ marginBottom: 2 }}>
					{application?.AppName}
				</Text>
				<Tooltip text="This is your current environment">
					<Environment type={application?.Environment || environment} />
				</Tooltip>
			</div>
		</div>
	);
};

export default ApplicationInfo;
