import React, { ReactNode, useMemo, useState } from "react";

import ApplicationContext, { initialState } from "./ApplicationContext";

interface Props {
	children: ReactNode;
}

const ApplicationContextProvider: React.FC<Props> = ({ children }) => {
	const [state, setState] = useState(initialState);

	const context = useMemo(() => ({ ...state, setState }), [state, setState]);

	return <ApplicationContext.Provider value={context}>{children}</ApplicationContext.Provider>;
};

export default ApplicationContextProvider;
