import React from "react";

import { Icon, Button as MukButton, Text } from "@makeen.io/material-ui-kit";
import * as R from "ramda";
import styled from "styled-components";

import theme from "theme/default";

import { FlexItem } from "../../Containers";

interface Props {
	data: any;
	propsList: string[];
	children: React.ReactNode;
}

type ControlDescriptionProps = {
	text: string;
} & React.HTMLAttributes<HTMLDivElement>;

const ControlDescription = styled(({ text, ...props }: ControlDescriptionProps) => (
	<div {...props}>
		<Text variant="subtitle1">{text}</Text>
	</div>
))`
	margin-bottom: 15px;
`;

const Signature: React.FC<Props> = ({ data, propsList, children }) => {
	const [showDescription, showButtonText, signatureDescription, signatureButtonText] = R.props(propsList, data);

	const buttonStyles = `
		&, &:hover {
			background-color: ${theme.palette.colors.basic[600]};
		}
	`;

	return (
		<>
			{showDescription && signatureDescription && <ControlDescription text={signatureDescription} />}
			<FlexItem direction="column">
				<MukButton customStyle={() => buttonStyles} leftIcon={<Icon fill="#fff" group="filled" name="pencil" />}>
					{showButtonText && signatureButtonText ? signatureButtonText : children}
				</MukButton>
			</FlexItem>
		</>
	);
};

export default Signature;
